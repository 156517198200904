import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const ViewAdmin = () => {
  const [user, setUser] = useState({});
  const [userId, setUserId] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    countryCode: "",
    contactNumber: "",
    fullContactNumber: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/profile`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setUser(response?.data);
        setUserId(response?.data?.data._id);
        setFormData({
          name: response?.data?.data?.name,
          email: response?.data?.data?.email,
          countryCode: response?.data?.data?.countryCode,
          contactNumber: response?.data?.data?.contactNumber,
          fullContactNumber:
            response?.data?.data?.countryCode +
            response?.data?.data?.contactNumber,
        });
      } catch (error) {
        console.error("Error fetching adminn details:", error);
      }
    };
    fetchUserDetails();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    } else if (formData.name.length > 20) {
      newErrors.name = "Name must be shorter then 20 words";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }
    if (!formData.contactNumber) {
      newErrors.contactNumber = "Contact number is required";
    } else if (!/^\d{8,15}$/.test(formData.contactNumber)) {
      toast.error("Contact number is invalid");
      newErrors.contactNumber = "Contact number is invalid";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePhoneChange = (value, country) => {
    console.log("value is : ", value);
    console.log("country is : ", country);

    const dialCode = country?.dialCode ? `+${country?.dialCode}` : "";

    const rawNumber = value.replace(country?.dialCode, "").trim();

    const rahul = rawNumber.split(dialCode)[1];
    console.log("Rahul; ", rahul);

    console.log("dialCode --", dialCode);
    console.log("rawNumber -- ", rawNumber);

    setFormData((prevData) => ({
      ...prevData,
      countryCode: dialCode,
      contactNumber: rawNumber,
      fullContactNumber: dialCode + rawNumber,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/admin/update-profile`, {
        id: userId,
        ...formData,
      });
      toast.success("Updated successfully!");
    } catch (error) {
      console.error("Error updating admin details:", error);
      alert("Failed to update admin details.");
    }
  };

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Admin Profile</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <form id="quickForm" onSubmit={handleFormSubmit}>
                    <div className="card-body">
                      {user && user.data && (
                        <>
                          <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input
                              type="text"
                              name="name"
                              className={`form-control ${
                                errors.name ? "is-invalid" : ""
                              }`}
                              placeholder="Enter name"
                              value={formData?.name}
                              onChange={handleInputChange}
                            />
                            {errors.name && (
                              <div className="invalid-feedback">
                                {errors.name}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                              type="text"
                              name="email"
                              className={`form-control ${
                                errors.email ? "is-invalid" : ""
                              }`}
                              placeholder="Enter email"
                              value={formData?.email}
                              onChange={handleInputChange}
                            />
                            {errors.email && (
                              <div className="invalid-feedback">
                                {errors.email}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="contactNumber">
                              Contact Number
                            </label>
                            <PhoneInput
                              country={"in"}
                              enableSearch={true}
                              placeholder="Enter Contact Number"
                              onChange={handlePhoneChange}
                              onKeyDown={(event) => {
                                if (
                                  event.key == "Backspace" &&
                                  formData?.contactNumber == ""
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              value={formData?.fullContactNumber}
                              inputStyle={{
                                width: "100%",
                                paddingLeft: "50px",
                                paddingRight: "15px",
                                borderRadius: "5px",
                                height: "43px",
                                border: "1px solid #ccc",
                                fontSize: "14px",
                              }}
                              inputClass={`form-control ${
                                errors.contactNumber ? "is-invalid" : ""
                              }`}
                            />
                            {errors.contactNumber && (
                              <div className="invalid-feedback">
                                {errors.contactNumber}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Save Changes
                      </button>
                      <Link
                        to="/admin-change-password"
                        className="btn btn-primary reset-btn"
                      >
                        Change Password
                      </Link>
                    </div>
                  </form>
                  {/* <div className="card-footer">
                    <Link to="/admin-reset-password" className="btn btn-primary">
                      Reset Password
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAdmin;
