import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ValidateInputs } from "../../helper/helper";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Loader from "../../components/Loader";

// Rahul

const CreateAccount = () => {
  const [user, setUser] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    countryCode: "",
    contactNumber: "",
    platformName: "",
    platformCategory: "",
    location: "",
    description: "",
    uniqueID: "", // Initialize uniqueID to empty string
    fullContactNumber: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { userId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/inquiry/view`,
          {
            params: { id: userId },
          }
        );
        setUser(response.data);
        setFormData({
          name: response?.data?.user?.name,
          email: response?.data?.user?.email,
          countryCode: response?.data?.user?.countryCode,
          contactNumber: response?.data?.user?.contactNumber,
          platformName: response?.data?.user?.platformName,
          platformCategory: response?.data?.user?.platformCategory,
          location: response?.data?.user?.location,
          description: response?.data?.user?.description,
          uniqueID: "", // Initialize uniqueID to empty string
          fullContactNumber:
            response?.data?.user?.countryCode +
            response?.data?.user?.contactNumber,
        });
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    fetchUserDetails();
  }, [userId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    const updatedInputs = ValidateInputs(name, value, errors);
    setErrors(updatedInputs);
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }

    if (!formData.contactNumber) {
      newErrors.contactNumber = "Contact number is required";
    } else if (!/^\d{8,15}$/.test(formData.contactNumber)) {
      toast.error("Contact number is invalid");
      newErrors.contactNumber = "Contact number is invalid";
    }
    if (!formData.platformName.trim()) {
      newErrors.platformName = "Platform name is required";
    }
    if (!formData.platformCategory.trim()) {
      newErrors.platformCategory = "Platform category is required";
    }
    if (!formData.location.trim()) {
      newErrors.location = "Location is required";
    }
    if (!formData.description.trim()) {
      newErrors.description = "Description is required";
    }
    if (!formData.uniqueID) {
      newErrors.uniqueID = "Password is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePhoneChange = (value, country) => {
    console.log("value is : ", value);
    console.log("country is : ", country);

    const dialCode = country?.dialCode ? `+${country?.dialCode}` : "";

    const rawNumber = value.replace(country?.dialCode, "").trim();

    setFormData((prevData) => ({
      ...prevData,
      countryCode: dialCode, // Store the country code
      contactNumber: rawNumber, // Store the contact number
      fullContactNumber: dialCode + rawNumber,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    console.log("formData is : ", formData);

    // return;
    setIsLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/inquiry/create-account`,
        {
          id: userId,
          ...formData,
        }
      );
      toast.success("Account created successfully!");
      navigate("/inquiry");
    } catch (error) {
      console.error("Error updating user details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const generateUniqueID = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 8; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const handleGenerateUniqueID = () => {
    const generatedID = generateUniqueID();
    setFormData((prevData) => ({
      ...prevData,
      uniqueID: generatedID,
    }));
  };

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Inquiry Management</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <form id="quickForm" onSubmit={handleFormSubmit}>
                    <div className="card-body">
                      {user && user.user && (
                        <>
                          <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input
                              type="text"
                              name="name"
                              className={`form-control ${
                                errors.name ? "is-invalid" : ""
                              }`}
                              placeholder="Enter name"
                              value={formData.name}
                              onChange={handleInputChange}
                              maxLength={70}
                            />
                            {errors.name && (
                              <div className="invalid-feedback">
                                {errors.name}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                              type="text"
                              name="email"
                              className={`form-control ${
                                errors.email ? "is-invalid" : ""
                              }`}
                              placeholder="Enter email"
                              value={formData.email}
                              onChange={handleInputChange}
                              disabled
                              maxLength={70}
                            />
                            {errors.email && (
                              <div className="invalid-feedback">
                                {errors.email}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="contactNumber">
                              Contact Number
                            </label>
                            <PhoneInput
                              country={"in"}
                              enableSearch={true}
                              placeholder="Enter Contact Number"
                              onChange={handlePhoneChange}
                              onKeyDown={(event) => {
                                if (
                                  event.key == "Backspace" &&
                                  formData?.contactNumber == ""
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              value={formData?.fullContactNumber}
                              inputStyle={{
                                width: "100%",
                                paddingLeft: "50px",
                                paddingRight: "15px",
                                borderRadius: "5px",
                                height: "43px",
                                border: "1px solid #ccc",
                                fontSize: "14px",
                              }}
                              inputClass={`form-control ${
                                errors.contactNumber ? "is-invalid" : ""
                              }`}
                            />

                            {errors.contactNumber && (
                              <div className="invalid-feedback">
                                {errors.contactNumber}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="name">Platform Name</label>
                            <input
                              type="text"
                              name="platformName"
                              className={`form-control ${
                                errors.platformName ? "is-invalid" : ""
                              }`}
                              placeholder="Enter platform name"
                              value={formData.platformName}
                              onChange={handleInputChange}
                              maxLength={70}
                            />
                            {errors.platformName && (
                              <div className="invalid-feedback">
                                {errors.platformName}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="name">Platform Category</label>
                            <input
                              type="text"
                              name="platformCategory"
                              className={`form-control ${
                                errors.platformCategory ? "is-invalid" : ""
                              }`}
                              placeholder="Enter platform category"
                              value={formData.platformCategory}
                              onChange={handleInputChange}
                              maxLength={70}
                            />
                            {errors.platformCategory && (
                              <div className="invalid-feedback">
                                {errors.platformCategory}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="name">Location</label>
                            <input
                              type="text"
                              name="location"
                              className={`form-control ${
                                errors.location ? "is-invalid" : ""
                              }`}
                              placeholder="Enter location"
                              value={formData.location}
                              onChange={handleInputChange}
                              maxLength={100}
                            />
                            {errors.location && (
                              <div className="invalid-feedback">
                                {errors.location}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="name">Description</label>
                            <textarea
                              type="text"
                              name="description"
                              className={`form-control ${
                                errors.description ? "is-invalid" : ""
                              }`}
                              placeholder="Enter description"
                              value={formData.description}
                              onChange={handleInputChange}
                              maxLength={500}
                            />
                            {errors.description && (
                              <div className="invalid-feedback">
                                {errors.description}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="uniqueID">Password</label>
                            <input
                              type="text"
                              name="uniqueID"
                              className={`form-control ${
                                errors.uniqueID ? "is-invalid" : ""
                              }`}
                              placeholder="Enter password"
                              value={formData.uniqueID}
                              onChange={handleInputChange}
                              maxLength={25}
                              minLength={8}
                            />
                            {errors.uniqueID && (
                              <div className="invalid-feedback">
                                {errors.uniqueID}
                              </div>
                            )}
                          </div>
                          <button
                            type="button"
                            onClick={handleGenerateUniqueID}
                          >
                            Generate Password
                          </button>
                        </>
                      )}
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Create Account
                      </button>
                    </div>
                  </form>
                  {isLoading && <Loader />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
