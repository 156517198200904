import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CustomPagination from "../../components/Pagination";

import { ChainIdEnum, getListErrors, NetworkEnum } from "../../helper/helper";

import Swal from "sweetalert2";

const WithdrawalLimit = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [limit, setLimit] = useState({});
  const [updatedLimit, setUpdatedLimit] = useState({});

  const fetchTokens = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/token/list`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            pageNo: currentPage,
            limitVal: 15,
            search: searchQuery,
          },
        }
      );

      const tokens = response?.data?.data.map((token) => ({
        ...token,
        // selectedNetwork: token?.network, // Default to POLYGON
        // selectedChainId: token.chainId || ChainIdEnum.POLYGON, // Default to POLYGON
      }));
      console.log("tokenResponse", tokens);
      setTokens(tokens);
      setCurrentPage(response?.data?.currentPage);
      setTotalPages(response?.data?.totalPages);

      const initialLimits = {};
      tokens.forEach((token) => {
        initialLimits[token?._id] = (1).toFixed(1); // Initialize each token's limit to 1.0
      });

      setUpdatedLimit(initialLimits); // Initialize updated limits to the same value
    } catch (error) {
      console.error("Error fetching tokens:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (event, tokenId, fieldName) => {
    const newValue = event.target.value;

    // Check if the input is a valid number and greater than or equal to 1
    if (!isNaN(newValue)) {
      setLimit((prevLimits) => ({
        ...prevLimits,
        [tokenId]: newValue, // Update the limit for the specific tokenId
      }));
    }

    setTokens((prevTokens) =>
      prevTokens.map((token) =>
        token._id === tokenId
          ? { ...token, [fieldName]: newValue } // Update the specific field
          : token
      )
    );
  };

  // const handleSetLimit = async (tokenId) => {
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_URL}/token/update-min-withdraw`,
  //       {
  //         tokenId: tokenId,
  //         minWithdraw: limit[tokenId],
  //         chainId: tokenId.selectedChainId,
  //         network: tokenId.selectedNetwork,
  //         code: tokenId.code,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     );
  //     //comment
  //     const limitValue = response?.data?.data?.minWithdraw;
  //     if (response.status === 201) {
  //       toast.success("Withdrawal Limit updated successfully!");
  //       setUpdatedLimit((prevLimits) => ({
  //         ...prevLimits,
  //         [tokenId]: limitValue,
  //       }));
  //       fetchTokens();
  //     } else {
  //       toast.error("Failed to update the Withdrawal Limit. Please try again.");
  //     }
  //   } catch (error) {
  //     const errorMessage = error?.response?.data?.message[0];
  //     console.error("Error updating Withdrawal Limit:", error.response);
  //     toast.error(errorMessage);
  //   }
  // };

  const handleSetLimit = async (tokenId) => {
    const token = tokens.find((t) => t._id === tokenId);
    if (!token) {
      toast.error("Token data not found!");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/token/update-min-withdraw`,
        {
          tokenId: tokenId,
          network: token?.network,
          chainId: token?.chainId,
          symbol: token?.symbol,
          address: token?.address,
          code: token?.code,
          decimal: token?.decimal,
          minWithdraw: limit[tokenId],
          minWithdraw: token.minWithdraw,
          minDeposit: token?.minDeposit,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const limitValue = response?.data?.data?.minWithdraw;
      if (response.status === 201) {
        toast.success("Token details updated successfully!");
        setUpdatedLimit((prevLimits) => ({
          ...prevLimits,
          [tokenId]: limitValue,
        }));
        fetchTokens();
      } else {
        toast.error("Failed to update the Withdrawal Limit. Please try again.");
      }
    } catch (error) {
      console.log("Error is : error : ", error);

      const errorMessage = getListErrors(error?.response?.data?.message);

      console.error("Error updating Withdrawal Limit:", errorMessage);
      toast.error(errorMessage);
      // alert("ra : " + error?.response?.data?.message);
    }
  };

  const handleNetworkChange = (event, tokenId) => {
    const { value } = event.target;
    console.log("tokenV", value);
    setTokens((prevTokens) =>
      prevTokens.map((token) =>
        token._id === tokenId ? { ...token, network: value } : token
      )
    );
  };

  const handleChainIdChange = (event, tokenId) => {
    const { value } = event.target;
    setTokens((prevTokens) =>
      prevTokens.map((token) =>
        token._id === tokenId ? { ...token, chainId: value } : token
      )
    );
  };

  useEffect(() => {
    fetchTokens();
  }, [currentPage, searchQuery]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const deleteToken = async (id) => {
    try {
      Swal.fire({
        title: "Delete Token!",
        text: "Are you sure want to delete Token?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete!",
      }).then(async (result) => {
        if (result?.isConfirmed) {
          const response = await axios.delete(
            `${process.env.REACT_APP_API_URL}/token/delete?id=${id}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          setTokens((prevTokens) =>
            prevTokens.filter((token) => token._id !== id)
          );
          toast.success("Deleted successfully!");
        }
      });
    } catch (error) {
      console.error("Error deleting faq:", error);
    }
  };

  // console.log("tokens", tokens)
  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2 d-flex justify-content-between">
              <div className="col-sm-4">
                <h1 className="m-0">Tokens Management</h1>
              </div>
              <div className="col-sm-2">
                <Link to="/withdrawal-limit-add-token">
                  <button className="btn btn-primary">
                    <FontAwesomeIcon icon={faPlus} />
                    {" Add More"}
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>S.N.</th>
                          <th>Network</th>
                          {/* <th>Chain Id</th> */}
                          <th>Symbol</th>
                          <th>Token Address</th>
                          <th>Code</th>
                          <th>Decimal</th>
                          <th>Min Withdrawal</th>
                          <th>Min Deposit</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {tokens?.length ? (
                          tokens.map((value, key) => (
                            <tr key={key}>
                              {/* SN */}
                              <td>{key + 1}</td>

                              {/* Network */}
                              <td>
                                <select
                                  className="form-control"
                                  value={value?.network.toUpperCase()}
                                  onChange={(event) =>
                                    handleNetworkChange(event, value?._id)
                                  }
                                >
                                  {Object.values(NetworkEnum).map((network) => (
                                    <option key={network} value={network}>
                                      {network}
                                    </option>
                                  ))}
                                </select>
                              </td>

                              {/* Chain Id */}
                              {/* <td>
                                <select
                                  className="form-control"
                                  value={value?.selectedChainId}
                                  onChange={(event) =>
                                    handleChainIdChange(event, value?._id)
                                  }
                                >
                                  {Object.entries(ChainIdEnum).map(
                                    ([name, id]) => (
                                      <option key={id} value={id}>
                                        {name}
                                      </option>
                                    )
                                  )}
                                </select>
                              </td> */}

                              {/* symbol */}
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="symbol"
                                  value={value?.symbol}
                                  onChange={
                                    (e) =>
                                      handleInputChange(e, value._id, "symbol") // Update symbol
                                  }
                                />
                              </td>

                              {/* Token Address */}
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="address"
                                  value={value?.address}
                                  onChange={
                                    (e) =>
                                      handleInputChange(e, value._id, "address") // Update address
                                  }
                                />
                              </td>

                              {/* code */}
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="code"
                                  value={value?.code}
                                  onChange={
                                    (e) =>
                                      handleInputChange(e, value._id, "code") // Update code
                                  }
                                />
                              </td>

                              {/* decimal */}
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="decimal"
                                  value={value?.decimal}
                                  onChange={
                                    (e) =>
                                      handleInputChange(e, value._id, "decimal") // Update decimal
                                  }
                                />
                              </td>

                              {/* Min Withdrawal */}

                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="minWithdraw"
                                  value={value?.minWithdraw}
                                  onChange={
                                    (e) =>
                                      handleInputChange(
                                        e,
                                        value._id,
                                        "minWithdraw"
                                      ) // Update minWithdraw
                                  }
                                />
                              </td>

                              {/* Min Deposit */}
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="minDeposit"
                                  value={value?.minDeposit}
                                  onChange={
                                    (e) =>
                                      handleInputChange(
                                        e,
                                        value._id,
                                        "minDeposit"
                                      ) // Update minDeposit
                                  }
                                />
                              </td>

                              {/* Action */}
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <button
                                    style={{
                                      backgroundColor: "#007bff",
                                      color: "#fff",
                                      border: "none",
                                      padding: "5px 10px",
                                      marginRight: "10px",
                                      cursor: "pointer",
                                      borderRadius: "4px",
                                    }}
                                    onClick={() => handleSetLimit(value?._id)}
                                  >
                                    Set
                                  </button>
                                  <button
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "#dc3545",
                                      border: "none",
                                      padding: "5px 10px",
                                      cursor: "pointer",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() => deleteToken(value?._id)}
                                  >
                                    <i className="fas fa-trash"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                  marginTop: "20px",
                                }}
                              >
                                <p>Data not found</p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <CustomPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawalLimit;
