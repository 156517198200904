import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import CustomPagination from "../../components/Pagination";

const AppList = (props) => {
  const merchantId = props?.merchantId;
  console.log(merchantId);
  const [apps, setApps] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [history, setHistory] = useState([]);

  const formatDate = (dateString) => {
    // Convert the date string to a Date object
    const date = new Date(dateString);

    // Array of month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Get day, month, year, hours, and minutes
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Format the date in "DD-Mon-YYYY hh:mmAM/PM" format
    const formattedDate = `${day}-${monthNames[monthIndex]}-${year} ${
      hours % 12 || 12
    }:${minutes < 10 ? "0" + minutes : minutes}${hours >= 12 ? " PM" : " AM"}`;

    return formattedDate;
  };

  const fetchApps = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/apps/list`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            merchantId,
            pageNo: currentPage,
            limitVal: 10,
            search: searchQuery,
          },
        }
      );
      console.log(localStorage.getItem("token"));
      setApps(response?.data?.data || []);
      setTotalPages(response?.data?.data?.totalPages);

      console.log(response?.data);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  useEffect(() => {
    fetchApps();
  }, [currentPage, searchQuery, merchantId]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row mb-2  justify-content-between">
          <div className="col-sm-6 mb-1 mt-5">
            <h1 className="m-0">App Details</h1>
          </div>
          {/* <div class="dropdown mb-1 mt-5">
            <button
              class="btn btn-primary dropdown-toggle"
              type="button"
              data-toggle="dropdown"
            >
              Download Report
              <span class="caret"></span>
            </button>
            <ul class="dropdown-menu">
              <li
                style={{ cursor: "pointer", backgroundColor: "#f8f9fa" }}
                // onClick={exportToPDF}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "#D3D3D3")
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = "#f8f9fa")
                }
              >
                Download PDF
              </li>
              <li
                style={{ cursor: "pointer", backgroundColor: "#f8f9fa" }}
                // onClick={exportToExcel}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "#D3D3D3")
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = "#f8f9fa")
                }
              >
                Download Excel
              </li>
            </ul>
          </div> */}
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <input
                  type="text"
                  placeholder="Search apps..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <table className="table">
                  <thead>
                    <tr>
                      <th>S.N.</th>
                      <th>App Name</th>
                      <th>Created On</th>
                      <th>Description</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {apps.length > 0 ? (
                      apps.map((user, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{user?.name}</td>
                          <td>{formatDate(user?.createdAt)}</td>
                          <td>{user?.description}</td>
                          <td>
                            <Link
                              to={`/merchants-apps-view/${user?._id}`}
                              className="btn btn-link merch-view"
                            >
                              <i className="fas fa-eye"></i>
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              marginTop: "20px",
                            }}
                          >
                            <p>Data not found</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <CustomPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppList;
