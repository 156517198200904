import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ValidateInputs } from "../../helper/helper";
import Loader from "../../components/Loader";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const UpdateContactUs = () => {
  const [user, setUser] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    countryCode: "",
    contactNumber: "",
    description: "",
    adminReply: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { userId } = useParams();
  const navigate = useNavigate();

  const [pricingData, setPricingData] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/contact-us/view`,
          {
            params: { id: userId },
          }
        );
        setUser(response?.data);
        setFormData({
          name: response?.data?.user?.name,
          email: response?.data?.user?.email,
          countryCode: response?.data?.user?.countryCode,
          contactNumber: response?.data?.user?.contactNumber,
          description: response?.data?.user?.description,
          adminReply: response?.data?.user?.adminReply,
        });
        if (response?.data?.pricingData) {
          setPricingData(response?.data?.pricingData);
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    fetchUserDetails();
  }, [userId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    const updatedInputs = ValidateInputs(name, value, errors);
    setErrors(updatedInputs);
  };

  const validate = () => {
    const newErrors = {};

    if (!formData?.adminReply?.trim()) {
      newErrors.adminReply = "Reply is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    setIsLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/contact-us/update`, {
        id: userId,
        ...formData,
      });
      toast.success("Replied successfully!");
      navigate("/contact-us");
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Error updating user details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Contact Us</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <form id="quickForm" onSubmit={handleFormSubmit}>
                    <div className="card-body">
                      {user && user.user && (
                        <>
                          <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input
                              type="text"
                              name="name"
                              className={`form-control ${
                                errors.name ? "is-invalid" : ""
                              }`}
                              placeholder="Enter name"
                              value={formData?.name}
                              readOnly
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                              type="text"
                              name="email"
                              className={`form-control ${
                                errors.email ? "is-invalid" : ""
                              }`}
                              placeholder="Enter email"
                              value={formData?.email}
                              readOnly
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="email">Contact Number</label>
                            <PhoneInput
                              country={"in"}
                              enableSearch={true}
                              placeholder="Enter Contact Number"
                              value={
                                formData?.countryCode + formData?.contactNumber
                              }
                              inputStyle={{
                                width: "100%",
                                paddingLeft: "50px",
                                paddingRight: "15px",
                                borderRadius: "5px",
                                height: "43px",
                                border: "1px solid #ccc",
                                fontSize: "14px",
                                backgroundColor: "#f9f9f9",
                                cursor: "not-allowed",
                              }}
                              disabled
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="name">Description</label>
                            <textarea
                              type="text"
                              name="description"
                              className={`form-control ${
                                errors.description ? "is-invalid" : ""
                              }`}
                              placeholder="Enter description"
                              value={formData?.description}
                              readOnly
                            />
                          </div>
                          {pricingData && (
                            <div className="pricing-data-section">
                              <h2>Pricing Details</h2>
                              <div className="form-group">
                                <label htmlFor="namee">Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter name"
                                  value={pricingData?.name}
                                  readOnly
                                />
                              </div>

                              <div className="form-group">
                                <label htmlFor="name">Title</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter title"
                                  value={pricingData?.title}
                                  readOnly
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="name">Pricing</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter pricing"
                                  value={pricingData?.pricing}
                                  readOnly
                                />
                              </div>
                              {/* <div className="form-group">
                                <label htmlFor="name">
                                  Pricing Description00
                                </label>
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={pricingData?.description || ""}
                                  config={{
                                    toolbar: [],
                                    readOnly: true,
                                  }}
                                />
                              </div> */}
                            </div>
                          )}
                          <div className="form-group">
                            <label htmlFor="adminReply">Admin Reply</label>
                            <textarea
                              type="text"
                              name="adminReply"
                              className={`form-control ${
                                errors.adminReply ? "is-invalid" : ""
                              }`}
                              placeholder="Reply here..."
                              value={formData?.adminReply}
                              onChange={handleInputChange}
                              maxLength={500}
                            />
                            {errors.adminReply && (
                              <div className="invalid-feedback">
                                {errors.adminReply}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Send Reply
                      </button>
                    </div>
                  </form>
                  {isLoading && <Loader />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateContactUs;
