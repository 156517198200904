import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { remove_p_br_Tags } from "../../helper/helper";

const UpdateContent = () => {
  const [page, setPage] = useState({});
  const [pageName, setPageName] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    subTitle: "",
    heading: "",
    description: "",
    otherValues: [],
    serviceHeading: [],
    serviceSubHeading: [],
  });
  const [errors, setErrors] = useState({});
  const { pageId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPageDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/pages/view`,
          {
            params: { id: pageId },
          }
        );
        setPage(response?.data);
        setPageName(response?.data?.page?.name);
        setFormData({
          title: response?.data?.page?.title,
          subTitle: response?.data?.page?.subTitle,
          heading: response?.data?.page?.heading || "",
          description: response?.data?.page?.description || "",
          otherValues: response?.data?.page?.otherValues || [],
          serviceHeading: response?.data?.page?.serviceHeading || [],
          serviceSubHeading: response?.data?.page?.serviceSubHeading || [],
        });
      } catch (error) {
        console.error("Error fetching page details:", error);
      }
    };
    fetchPageDetails();
  }, [pageId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      description: data,
    }));
  };

  const handleArrayInputChange = (e, index, key) => {
    const { value } = e.target;
    setFormData((prevData) => {
      const updatedArray = [...prevData[key]];
      updatedArray[index] = value;
      return {
        ...prevData,
        [key]: updatedArray,
      };
    });
  };

  console.log("formData?.description?.length ------- : ", remove_p_br_Tags(formData?.description), remove_p_br_Tags(formData?.description).length);

  const validate = () => {
    console.log("Vikas validation chal rha h kya? ");
    
    const newErrors = {};
    if (!formData?.title?.trim()) {
      newErrors.title = "Title is required";
    } else if (!formData?.subTitle?.trim()) {
      newErrors.subTitle = "Sub Title is required";
    } else if (formData.heading && !formData?.heading.trim()) {
      newErrors.heading = "Heading is required";
    } else if (formData.description && !formData?.description.trim()) {
      newErrors.description = "Description is required";
    } else if (formData.description && remove_p_br_Tags(formData?.description).length > 1000) {
      
      
      newErrors.description =
        "Description must be shorter than or equal to 1000 characters";
    }

    if (
      formData.serviceHeading.length > 0 &&
      formData.serviceHeading.some((val) => !val.trim())
    ) {
      newErrors.serviceHeading = formData.serviceHeading.map((value, index) => {
        const trimmedValue = value.trim();

        return !trimmedValue
          ? `Service Heading ${index + 1} is required`
          : null;
      });
    }

    if (
      formData.serviceSubHeading.length > 0 &&
      formData.serviceSubHeading.some((val) => !val.trim())
    ) {
      newErrors.serviceSubHeading = formData.serviceSubHeading.map(
        (value, index) => {
          const trimmedValue = value.trim();
          return !trimmedValue
            ? `Service Sub Heading ${index + 1} is required`
            : null;
        }
      );
    }

    if (
      formData.otherValues.length > 0 &&
      formData.otherValues.some((val) => !val.trim())
    ) {
      newErrors.otherValues = formData.otherValues.map((value, index) => {
        const trimmedValue = value.trim();
        return !trimmedValue ? `Val ${index + 1} is required` : null;
      });
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      // Check and filter out empty array elements
      const filteredOtherValues = formData.otherValues.filter(
        (val) => val.trim() !== ""
      );
      const filteredServiceHeading = formData.serviceHeading.filter(
        (val) => val.trim() !== ""
      );
      const filteredServiceSubHeading = formData.serviceSubHeading.filter(
        (val) => val.trim() !== ""
      );

      await axios.put(`${process.env.REACT_APP_API_URL}/pages/update`, {
        id: pageId,
        ...formData,
        otherValues: filteredOtherValues,
        serviceHeading: filteredServiceHeading,
        serviceSubHeading: filteredServiceSubHeading,
      });
      toast.success("Page details updated successfully!");
      navigate("/content-management");
    } catch (error) {
      console.log("Error updating content details:", error);
      toast.error(error.response.data.message[0] || error.response.data.message)
    }
  };

  class MyUploadAdapter {
    constructor(loader) {
      this.loader = loader;
    }
    upload() {
      return this.loader.file.then(
        (file) =>
          new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("image", file);
            console.log("formData", formData);

            axios
              .post(
                `${process.env.REACT_APP_API_URL}/pages/file-upload`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((response) => {
                resolve({
                  default: response?.data?.url,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
      );
    }

    abort() {}
  }

  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new MyUploadAdapter(loader);
    };
  }

  console.log("errors", errors);
  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {/* <h1 className="m-0">Content Management</h1> */}
                <h1 className="m-0">{pageName}</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <form id="quickForm" onSubmit={handleFormSubmit}>
                    <div className="card-body">
                      {page && page.page && (
                        <>
                          <div className="form-group">
                            <label htmlFor="title">Title</label>
                            <input
                              type="text"
                              name="title"
                              className={`form-control ${
                                errors.title ? "is-invalid" : ""
                              }`}
                              placeholder="Enter title"
                              value={formData.title}
                              onChange={handleInputChange}
                            />
                            {errors.title && (
                              <div className="invalid-feedback">
                                {errors.title}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="subTitle">Sub Title</label>
                            <input
                              type="text"
                              name="subTitle"
                              className={`form-control ${
                                errors.subTitle ? "is-invalid" : ""
                              }`}
                              placeholder="Enter Sub Title"
                              value={formData.subTitle}
                              onChange={handleInputChange}
                            />
                            {errors.subTitle && (
                              <div className="invalid-feedback">
                                {errors.subTitle}
                              </div>
                            )}
                          </div>
                          {formData && (
                            <div className="form-group">
                              <label htmlFor="heading">Heading</label>
                              <input
                                type="text"
                                name="heading"
                                className={`form-control ${
                                  errors.heading ? "is-invalid" : ""
                                }`}
                                placeholder="Enter heading"
                                value={formData.heading}
                                onChange={handleInputChange}
                              />
                              {errors.heading && (
                                <div className="invalid-feedback">
                                  {errors.heading}
                                </div>
                              )}
                            </div>
                          )}
                          {formData && (
                            <div className="form-group">
                              <label htmlFor="description">Description</label>

                              <CKEditor
                                editor={ClassicEditor}
                                data={formData.description}
                                onChange={handleEditorChange}
                                config={{
                                  extraPlugins: [MyCustomUploadAdapterPlugin],
                                }}
                              />
                              {errors.description && (
                                <div className="invalid-feedback d-block">
                                  {errors.description}
                                </div>
                              )}
                            </div>
                          )}

                          {formData?.otherValues.map((heading, index) => (
                            <div className="form-group" key={index}>
                              <label htmlFor={`otherValues-${index}`}>
                                Val {index + 1}
                              </label>
                              <input
                                type="text"
                                name={`otherValues-${index}`}
                                className={`form-control ${
                                  errors.otherValues &&
                                  errors.otherValues[index]
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={`Enter Service Heading ${
                                  index + 1
                                }`}
                                value={heading}
                                onChange={(e) =>
                                  handleArrayInputChange(
                                    e,
                                    index,
                                    "otherValues"
                                  )
                                }
                              />
                              {errors.otherValues &&
                                errors.otherValues[index] && (
                                  <div className="invalid-feedback">
                                    {errors.otherValues[index]}
                                  </div>
                                )}
                            </div>
                          ))}
                          {formData?.serviceHeading.map((heading, index) => (
                            <div className="row" key={index}>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor={`serviceHeading-${index}`}>
                                    Service Heading {index + 1}
                                  </label>
                                  <input
                                    type="text"
                                    name={`serviceHeading-${index}`}
                                    className={`form-control ${
                                      errors.serviceHeading &&
                                      errors.serviceHeading[index]
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    placeholder={`Enter Service Heading ${
                                      index + 1
                                    }`}
                                    value={heading}
                                    onChange={(e) =>
                                      handleArrayInputChange(
                                        e,
                                        index,
                                        "serviceHeading"
                                      )
                                    }
                                  />
                                  {errors.serviceHeading &&
                                    errors.serviceHeading[index] && (
                                      <div className="invalid-feedback">
                                        {errors.serviceHeading[index]}
                                      </div>
                                    )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor={`serviceSubHeading-${index}`}>
                                    Service Sub Heading {index + 1}
                                  </label>
                                  <input
                                    type="text"
                                    name={`serviceSubHeading-${index}`}
                                    className={`form-control ${
                                      errors.serviceSubHeading &&
                                      errors.serviceSubHeading[index]
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    placeholder={`Enter Service Sub Heading ${
                                      index + 1
                                    }`}
                                    value={formData.serviceSubHeading[index]}
                                    onChange={(e) =>
                                      handleArrayInputChange(
                                        e,
                                        index,
                                        "serviceSubHeading"
                                      )
                                    }
                                  />
                                  {errors.serviceSubHeading &&
                                    errors.serviceSubHeading[index] && (
                                      <div className="invalid-feedback">
                                        {errors.serviceSubHeading[index]}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateContent;
