import React, { useEffect, useState } from "react";
import axios from "axios";
import CustomPagination from "../../components/Pagination";
import "@fortawesome/fontawesome-free/css/all.min.css";

import { formatDate, trimAddress, copyToClipboard } from "../../helper/helper";
import { IoCopyOutline } from "react-icons/io5";

import Loader from "../../components/Loader";

const TransactionManagement = () => {
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [dropdownValue, setDropdownValue] = useState("");
  const [activeTab, setActiveTab] = useState("apps");
  const [isLoading, setIsLoading] = useState(false);

  const PaymentStatus = {
    "": "Select",
    "PENDING FROM BUYER SIDE": "PENDING FROM BUYER SIDE",
    "PARTIALLY SUCCESS": "PARTIALLY SUCCESS",
    SUCCESS: "SUCCESS",
  };
  const handleDropdownChange = (e) => {
    setDropdownValue(e.target.value); // store the key (e.g., "PENDING")
    setSearchQuery(""); // reset search query
    setCurrentPage(1); // reset pagination
  };

  const fetchAppsTransaction = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/merchant-app-tx/tx-list`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            pageNo: currentPage,
            limitVal: 10,
            search: dropdownValue ? dropdownValue : searchQuery,
          },
        }
      );

      setTransactions(response?.data?.data);
      setTotalPages(response?.data?.totalPages);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPaymentTransaction = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/payment-link/admin-all-payments`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            pageNo: currentPage,
            limitVal: 10,
            search: dropdownValue ? dropdownValue : searchQuery,
          },
        }
      );

      setTransactions(response?.data?.data);
      setTotalPages(response?.data?.totalPages);
    } catch (error) {
      console.error("Error fetching payment transactions:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab === "apps") {
      fetchAppsTransaction();
    } else if (activeTab === "payment") {
      fetchPaymentTransaction();
    }
  }, [currentPage, searchQuery, activeTab, dropdownValue]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setDropdownValue("");
    setCurrentPage(1);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1);
  };

  if (isLoading) {
    return <Loader />;
  }

  console.log("transactions", transactions);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2 d-flex justify-content-between">
              <div className="col-sm-6">
                <h1 className="m-0">Transaction Management</h1>
              </div>
              <div className="col-sm-0">
                <h4
                  className={`m-0 ${
                    activeTab === "apps" ? "btn btn-primary" : ""
                  }`}
                  onClick={() => handleTabChange("apps")}
                  style={{ cursor: "pointer" }}
                >
                  Apps
                </h4>
              </div>
              <div className="col-sm-2">
                <h4
                  className={`m-0 ${
                    activeTab === "payment" ? "btn btn-primary" : ""
                  }`}
                  onClick={() => handleTabChange("payment")}
                  style={{ cursor: "pointer" }}
                >
                  Payment
                </h4>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div
                    className="card-body"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <div>
                        <input
                          style={{
                            width: "400px",
                            padding: "5px",
                            borderRadius: "5px",
                            backgroundColor: "#f5f5f5",
                            border: "1px solid #ccc",
                          }}
                          type="text"
                          placeholder="Search here..."
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                      </div>

                      <div>
                        <select
                          style={{
                            marginLeft: "10px",
                            width: "300px",
                            height: "36px",
                            padding: "5px",
                            borderRadius: "5px",
                            backgroundColor: "#f5f5f5",
                            border: "1px solid #ccc",
                          }}
                          value={dropdownValue}
                          onChange={handleDropdownChange}
                          className="form-select"
                        >
                          {Object.entries(PaymentStatus).map(
                            ([key, value], index) => (
                              <option key={key} value={key}>
                                {" "}
                                {/* Use the key (PENDING, PARTIALLY, etc.) for value */}
                                {value}{" "}
                                {/* Display the label (PENDING FROM BUYER SIDE) */}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>

                    <div>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>SN</th>
                            <th>Chain Id</th>
                            <th>Transaction Hash</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Time Stamp</th>
                          </tr>
                        </thead>
                        <tbody>
                          {transactions?.length ? (
                            transactions.map((data, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{data?.chainId}</td>
                                <td>
                                  {trimAddress(data.hash, 10, 5)}

                                  {data.hash ? (
                                    <button
                                      onClick={() => copyToClipboard(data.hash)}
                                      style={{
                                        marginLeft: "1rem",
                                        border: "none",
                                        background: "transparent",
                                      }}
                                    >
                                      <IoCopyOutline />
                                    </button>
                                  ) : (
                                    "-------------"
                                  )}
                                </td>
                                <td>
                                  {trimAddress(data.fromAddress, 10, 5)}
                                  {data.fromAddress ? (
                                    <button
                                      onClick={() =>
                                        copyToClipboard(data.fromAddress)
                                      }
                                      style={{
                                        marginLeft: "1rem",
                                        border: "none",
                                        background: "transparent",
                                      }}
                                    >
                                      <IoCopyOutline />
                                    </button>
                                  ) : (
                                    "---------------"
                                  )}
                                </td>
                                <td>
                                  {trimAddress(
                                    data.toAddress ?? data.to,
                                    10,
                                    5
                                  )}
                                  <button
                                    onClick={() =>
                                      copyToClipboard(data.toAddress ?? data.to)
                                    }
                                    style={{
                                      marginLeft: "1rem",
                                      border: "none",
                                      background: "transparent",
                                    }}
                                  >
                                    <IoCopyOutline />
                                  </button>
                                </td>

                                <td>
                                  {data.recivedAmount ?? data.amount}{" "}
                                  {data.symbol}
                                </td>
                                <td>{data.status}</td>
                                <td>{formatDate(data?.createdAt)}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="7">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    marginTop: "20px",
                                  }}
                                >
                                  <p>Data not found</p>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <CustomPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionManagement;
