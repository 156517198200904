import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const [verificationToken, setVerificationToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState({
    newPassword: "",
    confirmPassword: "",
    general: "",
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    if (token) {
      setVerificationToken(token);
    }
  }, [location.search]);

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
    setError({ ...error, newPassword: "", general: "" });
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    setError({ ...error, confirmPassword: "", general: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let newErrors = {};

    if (!newPassword) {
      newErrors.newPassword = "Please enter a password.";
    } else if (newPassword.length < 6) {
      newErrors.newPassword = "Password must be at least 6 characters.";
    } else {
      const passwordRegex =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{6,}$/;
      if (!passwordRegex.test(newPassword)) {
        newErrors.newPassword =
          "Password must contain at least one uppercase letter, one lowercase letter, one special character, and one number.";
      }
    }

    if (!confirmPassword) {
      newErrors.confirmPassword = "Please confirm your password.";
    } else if (newPassword !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match.";
    }

    if (Object.keys(newErrors).length > 0) {
      setError(newErrors);
      return;
    }

    try {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/admin/reset-password`, {
          verificationToken: verificationToken,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
        })
        .then(() => {
          toast.success("Password changed successfully!");
          navigate("/login");
        })
        .catch((error) => {
          console.error("Error resetting password:", error);
          setError({
            ...error,
            general:
              error?.response?.data?.response?.error ||
              "Something went wrong. Please try again.",
          });
        });
    } catch (error) {
      console.log("catch error", error);
      setError({
        ...error,
        general: "An error occurred. Please try again later.",
      });
    }
  };

  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);
  const toggleConfirmPasswordVisibility = () =>
    setConfirmPasswordVisible(!confirmPasswordVisible);

  return (
    <>
      <div className="login-box">
        <div className="login-logo">
          <b style={{ fontSize: "Larger", color: "white" }}>Reset Password</b>
        </div>
        <div className="card forget-card">
          <div className="card-body register-card-body forget-register">
            <form onSubmit={handleSubmit}>
              {/* New Password Input */}
              <div className="input-group mb-2">
                <input
                  type={passwordVisible ? "text" : "password"}
                  className="form-control"
                  placeholder="Password"
                  value={newPassword}
                  onChange={handleNewPassword}
                />
                <div className="input-group-append">
                  <div
                    className="input-group-text"
                    onClick={togglePasswordVisibility}
                  >
                    <span>
                      <i
                        className={
                          passwordVisible ? "fas fa-eye-slash" : "fas fa-eye"
                        }
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
              {error.newPassword && (
                <span className="text-danger d-block mb-2">
                  {error.newPassword}
                </span>
              )}

              {/* Confirm Password Input */}
              <div className="input-group mb-2">
                <input
                  type={confirmPasswordVisible ? "text" : "password"}
                  className="form-control"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={handleConfirmPassword}
                />
                <div className="input-group-append">
                  <div
                    className="input-group-text"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    <span>
                      <i
                        className={
                          confirmPasswordVisible
                            ? "fas fa-eye-slash"
                            : "fas fa-eye"
                        }
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
              {error.confirmPassword && (
                <span className="text-danger d-block mb-2">
                  {error.confirmPassword}
                </span>
              )}

              {error.general && <p className="text-danger">{error.general}</p>}

              {/* Submit Button */}
              <div className="row" style={{ marginTop: "20px" }}>
                <div className="col-12">
                  <button type="submit" className="btn btn-primary btn-block">
                    Reset Password
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
