import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [wrongEmailMsg, setWrongEmailMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError(""); // Clear the error on input change
    setWrongEmailMsg(""); // Clear wrong email error
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setError("Please enter email.");
      return;
    }

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    setIsLoading(true);

    try {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/admin/verify-mail`, {
          email: email,
        })
        .then((response) => {
          toast.success("Reset Password Link sent to your mail id!");
          setEmail("");
          setWrongEmailMsg("");
          navigate("/login");
        })
        .catch((error) => {
          console.error("Error:", error);
          setWrongEmailMsg(
            "Invalid email address. Please enter a valid email address"
          );
        });
    } catch (error) {
      console.log("catch error", error);
      setError("Something went wrong. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="login-box">
        <div className="login-logo">
          <b style={{ fontSize: "Larger", color: "white" }}>Forgot Password</b>
        </div>

        <div className="card">
          <div className="card-body register-card-body">
            <form onSubmit={handleSubmit}>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  value={email}
                  onChange={handleEmailChange}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
              </div>
              {/* Error message for email */}
              {error && (
                <span className="text-danger d-block mb-2">{error}</span>
              )}
              {wrongEmailMsg && (
                <span className="text-danger d-block mb-2">
                  {wrongEmailMsg}
                </span>
              )}
              <div className="row">
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : "Submit"}
                  </button>
                </div>
              </div>
            </form>
            {isLoading && <Loader />}
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
