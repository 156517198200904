import React, { useEffect, useState } from "react";
import { IoCopyOutline } from "react-icons/io5";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  copyToClipboard,
  formatDate,
  getAddressLink,
  getListErrors,
} from "../../helper/helper";
import {
  getBalance,
  getERC20TransferTxFee,
  getNetwork,
} from "../../helper/evm.helper";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { EVM_CHAIN_ID_LIST, TRON_CHAIN_ID } from "../../helper/constant";

const ViewPaymentLink = () => {
  const { paymentId } = useParams();
  const [paymentInfo, setPaymentInfo] = useState("");
  const [txLink, setTxLink] = useState(""); // State to store the dynamic link
  const [isLoading, setIsLoading] = useState(false);
  const [cryptoInfo, setCryptoInfo] = useState({
    isNative: false,
    symbol: "",
    nativeSymbol: "",
    nativeBalance: 0,
    balance: 0,
    gas: 0,
  });

  const withdrawFund = async (amount, withdrawType) => {
    setIsLoading(true);
    try {
      const url = `${process.env.REACT_APP_API_URL}/payment-link/admin-paymentlink-fund-withdraw`;
      console.log("url is : ", url);

      const response = await axios.post(
        url,
        {
          paymentId: paymentId,
          chainId: paymentInfo?.chainId.toString(),
          amount: amount,
          withdrawType: withdrawType,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      console.log("response : ", response);

      if (response?.status == 201) {
        toast.success("Withdraw fund successfully!");
      } else {
        toast.error("Failed to withdraw fund. Please try again.");
      }
    } catch (error) {
      const e = getListErrors(error?.response?.data?.message);
      console.log("Error fetching admin fee : ", e);
      toast.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAppsDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/payment-link/get-tx-status`,
        {
          params: {
            paymentId,
          },
        }
      );

      if (response?.status == 200) {
        setPaymentInfo(response?.data?.data);

        // set the transaction link
        const link = await getAddressLink(
          response?.data?.data?.chainId,
          response?.data?.data?.toAddress
        );
        setTxLink(link); // Update the state with the fetched link

        const code = response?.data?.data?.code.split(".");

        setCryptoInfo({
          isNative: code.length == 2,
          symbol: code[0],
          nativeSymbol: code.length > 1 ? code[1] : code[0],
          nativeBalance: 0,
          balance: 0,
          gas: 0,
        });
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  useEffect(() => {
    fetchAppsDetails();
  }, [paymentId]);

  console.log("paymentInfo : ", paymentInfo);

  console.log("cryptoInfo : ", cryptoInfo);

  return (
    <div>
      {isLoading && <Loader />}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2 d-flex justify-content-between">
              <div className="col-sm-6 mb-2">
                <h1 className="m-0">View Payment Links </h1>
              </div>
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-body view-page">
                    <table className="table table-striped">
                      <tbody>
                        <tr>
                          <th>Payment ID</th>
                          <td>{paymentInfo?._id}</td>
                        </tr>
                        <tr>
                          <th>Chain ID</th>
                          <td>{paymentInfo?.chainId}</td>
                        </tr>
                        <tr>
                          <th>Crypto Code</th>
                          <td>{paymentInfo?.code}</td>
                        </tr>
                        <tr>
                          <th>Amount to Receive</th>
                          <td>
                            {paymentInfo?.amount} {paymentInfo?.symbol}
                          </td>
                        </tr>
                        <tr>
                          <th>Amount Received</th>
                          <td>
                            {paymentInfo?.recivedAmount ?? 0}{" "}
                            {paymentInfo?.symbol}
                          </td>
                        </tr>
                        <tr>
                          <th>Fund Receiver Address</th>
                          <td>
                            <a
                              href={txLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: "blue",
                                textDecoration: "underline",
                              }}
                            >
                              {paymentInfo?.toAddress}
                            </a>
                            <button
                              onClick={() =>
                                copyToClipboard(paymentInfo?.toAddress)
                              }
                              style={{
                                marginLeft: "1rem",
                                border: "none",
                                background: "transparent",
                                cursor: "pointer",
                              }}
                            >
                              <IoCopyOutline />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <th>Token Address</th>
                          <td>{paymentInfo?.tokenAddress}</td>
                        </tr>
                        <tr>
                          <th>Buyer Email</th>
                          <td>{paymentInfo?.buyerEmail}</td>
                        </tr>
                        <tr>
                          <th>Expiry Time</th>
                          <td>{formatDate(paymentInfo?.expireTime)}</td>
                        </tr>
                        <tr>
                          <th>Created At</th>
                          <td>{formatDate(paymentInfo?.createdAt)}</td>
                        </tr>
                        <tr>
                          <th>Payment Status</th>
                          <td>{paymentInfo?.status}</td>
                        </tr>
                        <tr>
                          <th>Withdrawal Status</th>
                          <td>{paymentInfo?.withdrawStatus}</td>
                        </tr>
                        <tr>
                          <th>Admin Fee</th>
                          <td>{paymentInfo?.adminFee ?? "-"}</td>
                        </tr>
                        <tr>
                          <th>Admin Fee Wallet</th>
                          <td>{paymentInfo?.adminFeeWallet ?? "-"}</td>
                        </tr>
                        <tr>
                          <th>Merchant Amount</th>
                          <td>{paymentInfo?.amountAfterTax ?? "-"}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 mb-2">
                <h2 className="m-0">Current Balance</h2>
              </div>
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-body view-page">
                    <table className="table table-striped">
                      <tbody>
                        <tr>
                          <th>Transaction Fee</th>
                          <td>
                            {cryptoInfo?.gas} {cryptoInfo?.nativeSymbol}
                          </td>
                        </tr>
                        {cryptoInfo?.isNative && (
                          <tr>
                            <th>{cryptoInfo?.nativeSymbol} Balance</th>
                            <td>
                              {cryptoInfo?.nativeBalance}{" "}
                              {cryptoInfo?.nativeSymbol}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <th>{cryptoInfo?.symbol} Balance</th>
                          <td>
                            {cryptoInfo?.balance} {cryptoInfo?.symbol}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    {/* Action Buttons */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <button
                        onClick={async () => {
                          let output = null;
                          if (
                            EVM_CHAIN_ID_LIST.includes(paymentInfo?.chainId)
                          ) {
                            output = await getERC20TransferTxFee(
                              await getNetwork(paymentInfo?.chainId.toString())
                                .rpc,
                              paymentInfo?.tokenAddress,
                              paymentInfo?.amount,
                              paymentInfo?.tokenDecimals,
                              paymentInfo?.toAddress,
                              "0x5B18D497d088540c4434Db7824cC9b1b06f02805"
                            );
                          } else {
                            output = { gasFeeInEther: 12 };
                          }

                          console.log("output: ", output);

                          setCryptoInfo((prev) => ({
                            ...prev,
                            gas: output?.gasFeeInEther ?? prev.gas,
                          }));
                        }}
                      >
                        Fetch Required Transaction Fee
                      </button>
                      <button
                        onClick={async () => {
                          setIsLoading(true);
                          console.log("Withdraw Fee");
                          let balance;
                          if (
                            EVM_CHAIN_ID_LIST.includes(paymentInfo?.chainId)
                          ) {
                            balance = await getBalance(
                              paymentInfo?.chainId.toString(),
                              paymentInfo?.toAddress,
                              paymentInfo?.tokenAddress
                            );
                            // getTronTokenBalance
                          } else if (paymentInfo?.chainId === TRON_CHAIN_ID) {
                            const url = `${process.env.REACT_APP_API_URL}/payment-link/get-tron-balance`;
                            console.log("url is : ", url);

                            const response = await axios.post(
                              url,
                              {
                                paymentId: paymentId,
                              },
                              {
                                headers: {
                                  Authorization: `Bearer ${localStorage.getItem(
                                    "token"
                                  )}`,
                                },
                              }
                            );

                            console.log("Response vv : ", response);

                            if (response.status === 201) {
                              balance = response.data;
                            }
                          } else {
                            console.log("Invalid chainId in Withdraw Fee");
                          }
                          setIsLoading(false);

                          setCryptoInfo((prev) => ({
                            ...prev,
                            nativeBalance:
                              balance?.ethBalanceEther ?? prev.ethBalanceEther,
                            balance:
                              balance?.tokenBalanceEther ??
                              prev.tokenBalanceEther,
                          }));

                          console.log("balance: ", balance);
                        }}
                      >
                        Fetch Updated Balance
                      </button>
                    </div>

                    <div
                      style={{
                        display: "flex",

                        gap: "10px",
                        marginTop: "10px",
                        marginTop: "20px",
                      }}
                    >
                      {/* Left Form */}
                      <form
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: "20%", // Adjust for even spacing
                        }}
                        onSubmit={async (e) => {
                          e.preventDefault();
                          const amount = Number(e.target.elements[0].value); // Fetch the input value
                          const withdrawType = "ADMIN CHARGE"; // "MERCHANT", //
                          await withdrawFund(amount, withdrawType); // Pass the amount to the API
                        }}
                      >
                        <label>
                          Admin Fee Withdraw:
                          <input
                            type="double"
                            placeholder="Enter amount"
                            style={{
                              width: "100%",
                              padding: "5px",
                              marginTop: "5px",
                            }}
                          />
                        </label>
                        <button type="submit">Submit</button>
                      </form>

                      {/* Right Form */}
                      <form
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: "20%", // Adjust for even spacing
                        }}
                        onSubmit={async (e) => {
                          e.preventDefault();
                          console.log("e.target : ", e.target);

                          const amount = Number(e.target.elements[0].value); // Fetch the input value
                          console.log("amount : ", amount, typeof amount);

                          const withdrawType = "MERCHANT";
                          await withdrawFund(amount, withdrawType);
                        }}
                      >
                        <label>
                          Merchant Fund Withdraw:
                          <input
                            type="double"
                            placeholder="Enter amount"
                            style={{
                              width: "100%",
                              padding: "5px",
                              marginTop: "5px",
                            }}
                          />
                        </label>
                        <button type="submit">Submit</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPaymentLink;
