import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ValidateInputs } from "../../helper/helper";
import { socket } from "../../helper/socket";
import Loader from "../../components/Loader";

const UpdateCustomerSupport = () => {
  const [adminId] = useState("admin"); // Set dummy adminId
  const [message, setMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { userId } = useParams();

  useEffect(() => {
    // Ensure socket connection is established
    if (socket) {
      socket.emit("handleJoinRoom", { userId, adminId });
    }
  }, [userId, adminId]);

  // Listen for incoming messages from admin
  useEffect(() => {
    console.log("This is called");

    try {
      const chatToUser = (chat) => {
        console.log("Chat from user: ", chat);
        displayMessage("user", chat.message);
      };

      // Register socket event listeners for receiving messages
      socket.on("chat_to_admin", chatToUser);

      // Cleanup socket event listeners when the component unmounts
      return () => {
        socket.off("chat_to_admin", chatToUser);
      };
    } catch (e) {
      console.log("Error is : ", e);
    }
  }, []); // No dependencies needed here

  // Function to display messages on the user's chat screen
  const displayMessage = (sender, message) => {
    setChatMessages((prevMessages) => [...prevMessages, { sender, message }]);
  };
  // Function to handle sending messages
  const handleMessageSend = () => {
    if (message.trim()) {
      const payload = {
        userId,
        adminId,
        message,
      };

      // Emit message to server
      socket.emit("message_from_admin", payload);

      // Display the message on user's screen
      displayMessage("admin", message);
      setMessage(""); // Clear input field
    }
  };

  const readOldChat = async () => {
    try {
      const url = `${process.env.REACT_APP_EXTENSION_API_URL}/chat/history/${userId}/${adminId}`;
      console.log("url : ", url);

      const response = await axios.get(url);

      const oldChatMessages = response?.data || []; // Assuming the chat data is in response.data.chats

      // Map the old messages to the desired format for display (e.g., sender, message)
      oldChatMessages.map((chat) =>
        displayMessage(chat?.isAdminMessage ? "admin" : "user", chat.message)
      );

      console.log("Response of old chat read: ", oldChatMessages);
    } catch (error) {
      console.error("Error fetching old chat:", error);
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_EXTENSION_API_URL}/users/view`,
          {
            params: { id: userId }, // Ensure userId is passed correctly if needed
          }
        );
        setUser(response?.data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    fetchUserDetails();
    readOldChat();
  }, [userId]);

  console.log("User is : ", user);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Customer Support Management 8547</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="card card-primary"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="card-body">
                    {user && user.user && (
                      <>
                        <div
                          className="form-group"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <p>
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                Name
                              </span>
                              : {user?.user?.name}
                            </p>
                          </div>
                          <div>
                            <p>
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                Email
                              </span>
                              : {user?.user?.email}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="chat-body" id="chat-body">
                    {chatMessages.map((chat, index) => (
                      <div key={index} className={`message ${chat.sender}`}>
                        <p>{chat.message}</p>
                      </div>
                    ))}
                  </div>

                  <div
                    className="chat-footer"
                    style={{
                      marginBottom: "10px",
                      width: "700px",
                    }}
                  >
                    <input
                      type="text"
                      id="message-input"
                      placeholder="Type a message..."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <button id="send-button" onClick={handleMessageSend}>
                      Send
                    </button>
                  </div>

                  {isLoading && <Loader />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateCustomerSupport;
