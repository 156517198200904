import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // Main styles
import "react-date-range/dist/theme/default.css"; // Theme styles
import "./Report.css";
import CustomTable from "./CustomTable";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";

const CustomReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [chartDataOptions] = useState([
    { value: "payment_links", label: "Payment Links" },
    {
      value: "merchant_app_revenue_reports",
      label: "Merchant App Revenue Reports",
    },
    {
      value: "payment_link_revenue_reports",
      label: "Payment Link Revenue Reports",
    },
    // { value: "crypto_margin", label: "Crypto Margin" },
    { value: "merchant_reports", label: "Merchant Deposit/Withdraw" },
  ]);

  const [tokensListOptions] = useState([
    { value: "USDT", label: "USDT" },
    { value: "USDC", label: "USDC" },
    { value: "TRON", label: "TRON" },
    { value: "BTC", label: "BTC" },
    { value: "ETH", label: "ETH" },
    { value: "BNB", label: "BNB" },
    { value: "TRX", label: "TRX" },
  ]);

  const [timeFormatOptions] = useState([
    { value: "DAYS", label: "DAYS" },
    { value: "WEEKS", label: "WEEK" },
    { value: "MONTHS", label: "MONTH" },
    { value: "YEARS", label: "YEARS" },
  ]);

  const [selectedChartData, setSelectedChartData] = useState("payment_links");
  const [selectedToken, setSelectedToken] = useState("");
  const [selectedTimeFormat, setSelectedTimeFormat] = useState("");

  const [apiResult, setApiResult] = useState(null);

  const handleDateChange = (ranges) => {
    setDateRange(ranges.selection);
    setShowDatePicker(false); // Hide date picker after selection
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const payload = {
      startDate: dateRange?.startDate.toLocaleDateString("en-GB"),
      endDate: dateRange?.endDate.toLocaleDateString("en-GB"),
      token: selectedToken,
      timeFormat: selectedTimeFormat.toUpperCase(),
    };

    try {
      let response;

      if (selectedChartData === "merchant_reports") {
        // Call merchant_reports API
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/payment-link/table-count-merchant-deposit-withdraw`,
          payload
        );
      } else if (selectedChartData === "merchant_app_revenue_reports") {
        // Call merchant_reports API
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/payment-link/table-merchant-app-revenue-report`,
          payload
        );
      } else if (selectedChartData === "payment_link_revenue_reports") {
        // Call merchant_reports API
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/payment-link/table-payment-link-revenue-report`,
          payload
        );
      } else {
        // Default API for other chart data
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/payment-link/table-count-payment-links`,
          payload
        );
      }

      console.log("API Response:", response);
      setApiResult(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error(error?.response?.data?.message[0]);
    } finally {
      setIsLoading(false);
    }
  };

  console.log("apiResult", apiResult);
  console.log("selectedTimeFormat00022", selectedTimeFormat);

  return (
    <div>
      <div className="content-header">
        <section className="content">
          <div className="container-fluid">
            <div className="container-fluid main-diagram" id="all-chart">
              <div className="row mb-2 diagram-parent">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-primary">
                        <form
                          id="quickForm"
                          onSubmit={handleSubmit} // Call the handleSubmit function here
                        >
                          <div className="card-body">
                            <div className="row">
                              {/* Chart Data Dropdown */}
                              <div className="form-group col-md-3">
                                <label>Chart Data</label>
                                <select
                                  className="form-control select2"
                                  value={selectedChartData}
                                  onChange={(e) =>
                                    setSelectedChartData(e.target.value)
                                  }
                                >
                                  <option value="" disabled>
                                    Select Chart Data
                                  </option>
                                  {chartDataOptions.map((option, index) => (
                                    <option key={index} value={option.value}>
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              {/* Tokens List Dropdown */}
                              <div className="form-group col-md-3">
                                <label>Tokens List</label>
                                <select
                                  className="form-control select2"
                                  value={selectedToken}
                                  onChange={(e) =>
                                    setSelectedToken(e.target.value)
                                  }
                                >
                                  <option value="" disabled>
                                    Select Token
                                  </option>
                                  {tokensListOptions.map((option, index) => (
                                    <option key={index} value={option.value}>
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              {/* Time Format */}
                              <div className="form-group col-md-3">
                                <label>Time Format</label>
                                <select
                                  className="form-control select2"
                                  value={selectedTimeFormat}
                                  onChange={(e) =>
                                    setSelectedTimeFormat(e.target.value)
                                  }
                                >
                                  <option value="" disabled>
                                    Select timeFormat
                                  </option>
                                  {timeFormatOptions.map((option, index) => (
                                    <option key={index} value={option.value}>
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              {/* Date Range Picker */}
                              <div className="form-group col-md-2">
                                <label>Date Range:</label>
                                <div className="input-group">
                                  <button
                                    type="button"
                                    className="btn btn-default"
                                    onClick={() =>
                                      setShowDatePicker(!showDatePicker)
                                    }
                                  >
                                    <i className="far fa-calendar-alt"></i>{" "}
                                    {`${dateRange.startDate.toLocaleDateString()} - ${dateRange.endDate.toLocaleDateString()}`}
                                    <i className="fas fa-caret-down"></i>
                                  </button>
                                </div>
                              </div>

                              {/* Submit Button */}
                              <div className="form-group col-md-1">
                                <label>&nbsp;</label>
                                <div className="input-group">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>

                            {showDatePicker && (
                              <DateRangePicker
                                ranges={[dateRange]}
                                onChange={handleDateChange}
                                className="date-picker"
                              />
                            )}
                          </div>
                        </form>
                        {isLoading && <Loader />}
                      </div>
                    </div>
                    {/* <CustomTable /> */}
                    <CustomTable
                      apiResult={apiResult}
                      timeFormat={selectedTimeFormat}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CustomReport;
