import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "@fortawesome/fontawesome-free/css/all.min.css";

import Swal from "sweetalert2";

const ChangePassword = () => {
  const [userId, setUserId] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});

  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  //show password
  const toggleCurrentPasswordVisibility = () => {
    console.log("toggleCurrentPasswordVisibility : ", currentPasswordVisible);

    setCurrentPasswordVisible(!currentPasswordVisible);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/profile`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setUserId(response?.data?.data?._id);
      } catch (error) {
        console.error("Error fetching admin details:", error);
      }
    };
    fetchUserDetails();
  }, []);

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const validateForm = () => {
    const errors = {};
    if (!currentPassword) {
      errors.currentPassword = "Current password cannot be empty.";
    } else if (!newPassword) {
      errors.newPassword = "New password cannot be empty.";
    } else if (!confirmPassword) {
      errors.confirmPassword = "Confirm password cannot be empty.";
    } else if (newPassword.length < 6) {
      errors.newPassword = "Password must be at least 6 characters long.";
    } else if (
      !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+|}{":?><,./;'[\]\\`~]).{6,}$/.test(
        newPassword
      )
    ) {
      errors.newPassword =
        "Password must contain at least one uppercase letter, one lowercase letter, one special character and one number.";
    } else if (newPassword !== confirmPassword) {
      errors.confirmPassword =
        "Confirm password do not match with new password.";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to reset the password?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, reset it!",
      cancelButtonText: "No, cancel!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.post(
            `${process.env.REACT_APP_API_URL}/admin/change-password`,
            {
              id: userId,
              currentPassword: currentPassword,
              newPassword: newPassword,
              confirmPassword: confirmPassword,
            }
          );
          localStorage.removeItem("token");
          toast.success("Password change successfully!");
          navigate("/login");
        } catch (error) {
          console.error(
            "Error resetting password:",
            error?.response?.data?.message
          );
          toast.error(
            error?.response?.data?.message || "Failed to reset password."
          );
        }
      }
    });
  };

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Change Password</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <form id="quickForm" onSubmit={handleSubmit}>
                    <div className="card-body">
                      <>
                        <div className="form-group">
                          <label htmlFor="currentPassword">
                            Current Password
                          </label>
                          <div className="input-group">
                            <input
                              type={
                                !currentPasswordVisible ? "password" : "text"
                              }
                              name="currentPassword"
                              className="form-control"
                              placeholder="Current Password"
                              value={currentPassword}
                              onChange={(e) => {
                                setCurrentPassword(e.target.value);
                              }}
                            />

                            <div className="input-group-append">
                              <span
                                className="input-group-text"
                                onClick={toggleCurrentPasswordVisibility}
                              >
                                <i
                                  className={
                                    currentPasswordVisible
                                      ? "fas fa-eye-slash"
                                      : "fas fa-eye"
                                  }
                                ></i>
                              </span>
                            </div>
                          </div>

                          {errors.currentPassword && (
                            <small className="form-text text-danger">
                              {errors.currentPassword}
                            </small>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="newPassword">New Password</label>
                          <div className="input-group">
                            <input
                              type={passwordVisible ? "text" : "password"}
                              name="newPassword"
                              className="form-control"
                              placeholder="New Password"
                              value={newPassword}
                              onChange={handleNewPassword}
                            />
                            <div className="input-group-append">
                              <span
                                className="input-group-text"
                                onClick={togglePasswordVisibility}
                              >
                                <i
                                  className={
                                    passwordVisible
                                      ? "fas fa-eye-slash"
                                      : "fas fa-eye"
                                  }
                                ></i>
                              </span>
                            </div>
                          </div>
                          {errors.newPassword && (
                            <small className="form-text text-danger">
                              {errors.newPassword}
                            </small>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="confirmPassword">
                            Confirm Password
                          </label>
                          <div className="input-group">
                            <input
                              type={
                                confirmPasswordVisible ? "text" : "password"
                              }
                              name="confirmPassword"
                              className="form-control"
                              placeholder="Confirm Password"
                              value={confirmPassword}
                              onChange={handleConfirmPassword}
                            />
                            <div className="input-group-append">
                              <span
                                className="input-group-text"
                                onClick={toggleConfirmPasswordVisibility}
                              >
                                <i
                                  className={
                                    confirmPasswordVisible
                                      ? "fas fa-eye-slash"
                                      : "fas fa-eye"
                                  }
                                ></i>
                              </span>
                            </div>
                          </div>
                          {errors.confirmPassword && (
                            <small className="form-text text-danger">
                              {errors.confirmPassword}
                            </small>
                          )}
                        </div>
                      </>
                    </div>

                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Change Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
