export const BNB_CHAIN_ID = "97";
export const ETH_CHAIN_ID = "11155111";
export const POLYGON_CHAIN_ID = "80002";

export const BTC_CHAIN_ID = "BTC";
export const TRON_CHAIN_ID = "TRON";

export const EVM_CHAIN_ID_LIST = [BNB_CHAIN_ID, ETH_CHAIN_ID, POLYGON_CHAIN_ID];

export const BNB_ADDRESS_SCAN_URL = "https://testnet.bscscan.com/address/";
export const ETH_ADDRESS_SCAN_URL = "https://sepolia.etherscan.io/address/";
export const POLYGON_ADDRESS_SCAN_URL = "https://amoy.polygonscan.com/address/";
export const TRON_ADDRESS_SCAN_URL = "https://shasta.tronscan.org/#/address/";
export const BTC_ADDRESS_SCAN_URL = "https://mempool.space/testnet/address/";
