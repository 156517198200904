import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { IoCopyOutline } from "react-icons/io5";
import QRCode from "react-qr-code";
import axios from "axios";
import { useParams } from "react-router-dom";
import ViewAppTransaction from "./ViewAppTransaction";
import { copyToClipboard } from "../../helper/helper";

const ViewApp = () => {
  const { userId } = useParams();
  const [appDetails, setAppDetails] = useState("");
  const [depositAddress, setDepositAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchAppsDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/apps/view`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            id: userId,
            // pageNo: currentPage,
            // limitVal: 10,
            // search: searchQuery,
          },
        }
      );
      //   setApps(response?.data?.data || []);
      //   setTotalPages(response?.data?.data?.totalPages);
      if (response?.data) {
        setAppDetails(response?.data?.app);
        setDepositAddress(response?.data?.app?.EVMWalletMnemonic?.address);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  useEffect(() => {
    fetchAppsDetails();
  }, [userId, depositAddress]);

  console.log("appDetails", appDetails);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2 d-flex justify-content-between">
              <div className="col-sm-6 mb-2">
                <h1 className="m-0">App Wallet Details</h1>
              </div>
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-body view-page">
                    <>
                      <div className="user-info">
                        <strong>API KEYS:</strong>
                        <span style={{ marginLeft: "2rem" }}>
                          ***************************
                        </span>
                        <button
                          onClick={() => copyToClipboard(appDetails?.apiKey)}
                          style={{
                            marginLeft: "2rem",
                            border: "none",
                            background: "transparent",
                          }}
                        >
                          <IoCopyOutline />
                        </button>
                      </div>
                      <div className="user-info">
                        <strong>SECRET KEYS:</strong>
                        <span style={{ marginLeft: "2rem" }}>
                          ***************************
                        </span>
                        <button
                          onClick={() => copyToClipboard(appDetails?.secretKey)}
                          style={{
                            marginLeft: "2rem",
                            border: "none",
                            background: "transparent",
                          }}
                        >
                          <IoCopyOutline />
                        </button>
                      </div>
                  
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        {/* Deposit Address */}
                        {depositAddress && (
                          <div className="user-info">
                            <strong>Evm Wallet Address:</strong>
                            <span style={{ marginLeft: "1rem" }}>
                              {depositAddress}
                            </span>
                            <button
                              onClick={() => copyToClipboard(depositAddress)}
                              style={{
                                marginLeft: "2rem",
                                border: "none",
                                background: "transparent",
                              }}
                            >
                              <IoCopyOutline />
                            </button>
                            <div
                              style={{
                                height: "auto",
                                margin: "1rem auto 0",
                                maxWidth: 150,
                                width: "100%",
                              }}
                            >
                              <QRCode
                                size={256}
                                style={{
                                  height: "auto",
                                  maxWidth: "100%",
                                  width: "100%",
                                }}
                                value={depositAddress}
                                viewBox={`0 0 256 256`}
                              />
                            </div>
                          </div>
                        )}

                        {/* Bitcoin Wallet */}
                        {appDetails?.BtcWalletMnemonic?.address && (
                          <div className="user-info">
                            <strong>BitCoin Wallet Address:</strong>
                            <span style={{ marginLeft: "1rem" }}>
                              {appDetails?.BtcWalletMnemonic?.address}
                            </span>
                            <button
                              onClick={() =>
                                copyToClipboard(
                                  appDetails?.BtcWalletMnemonic?.address
                                )
                              }
                              style={{
                                marginLeft: "2rem",
                                border: "none",
                                background: "transparent",
                              }}
                            >
                              <IoCopyOutline />
                            </button>
                            <div
                              style={{
                                height: "auto",
                                margin: "1rem auto 0",
                                maxWidth: 150,
                                width: "100%",
                              }}
                            >
                              <QRCode
                                size={256}
                                style={{
                                  height: "auto",
                                  maxWidth: "100%",
                                  width: "100%",
                                }}
                                value={appDetails?.BtcWalletMnemonic?.address}
                                viewBox={`0 0 256 256`}
                              />
                            </div>
                          </div>
                        )}

                        {/* Tron Wallet */}
                        {appDetails?.TronWalletMnemonic?.address && (
                          <div className="user-info">
                            <strong>Tron Wallet Address:</strong>
                            <span style={{ marginLeft: "1rem" }}>
                              {appDetails?.TronWalletMnemonic?.address}
                            </span>
                            <button
                              onClick={() =>
                                copyToClipboard(
                                  appDetails?.TronWalletMnemonic?.address
                                )
                              }
                              style={{
                                marginLeft: "2rem",
                                border: "none",
                                background: "transparent",
                              }}
                            >
                              <IoCopyOutline />
                            </button>
                            <div
                              style={{
                                height: "auto",
                                margin: "1rem auto 0",
                                maxWidth: 150,
                                width: "100%",
                              }}
                            >
                              <QRCode
                                size={256}
                                style={{
                                  height: "auto",
                                  maxWidth: "100%",
                                  width: "100%",
                                }}
                                value={appDetails?.TronWalletMnemonic?.address}
                                viewBox={`0 0 256 256`}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ViewAppTransaction address={depositAddress} />
        </div>
      </div>
    </div>
  );
};

export default ViewApp;
