import React, { useState, useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import axios from "axios";
import "./Report.css";
import jsPDF from "jspdf";
import { HiOutlineDownload } from "react-icons/hi";
import html2canvas from "html2canvas";
import { TIME_PERIOD } from "../../helper/helper";
import { time } from "highcharts";

const Reports = (props) => {
  let [paymentLinkChart, setPaymentLinkChart] = useState({});
  // const [revenueReportChartData, setRevenueReportChartData] = useState({});
  const [revenueChart, setRevenueChart] = useState({});
  const [cryptoMarginsChart, setCryptoMarginsChart] = useState([]);
  const [merchantDeposits, setMerchantDeposits] = useState([]);
  const [merchantWithdrawals, setMerchantWithdrawals] = useState([]);
  const [activePaymentLinks, setActivePaymentLinks] = useState(0);
  const [activeMerchantApps, setActiveMerchantApps] = useState(0);
  const [chartTime, setChartTime] = useState(TIME_PERIOD.MONTHLY);
  const [timeLabels, setTimeLabels] = useState([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);
  const chartRef3 = useRef(null);
  const chartRef4 = useRef(null);

  const [dropdownValue, setDropdownValue] = useState("");
  const [graphCoin, setGraphCoin] = useState(null);
  const [selectCoin, setSelectCoin] = useState({});

  const handleDropdownChange = (e) => {
    setDropdownValue(e.target.value);
    console.log("handleDropdownChange : ", e.target.value);
    setGraphCoin(e.target.value);
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const quarterNames = ["Jan - Mar", "Apr - Jun", "Jul - Sep", "Oct - Dec"];

  const fetchRevenueChart = async (time) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/payment-link/admin-fee-sum`,
        {
          timePeriod: time,
          symbol: graphCoin,
        }
      );
      if (response?.data) {
        console.log("------ : response?.data : ", response?.data);

        const rawData = response?.data || [];
        // setRevenueReportChartData(rawData);
        setRevenueChart(rawData);
        setTimeLabels(response?.data?.periods);
      }
    } catch (error) {
      console.error("Error fetching revenue", error);
    }
  };

  const fetchPaymentLinkCount = async (time) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/payment-link/count`,
        {
          timePeriod: time,
        }
      );

      const rawData = response?.data?.monthlyCounts || [];
      const timePeriodArray = response?.data?.timePeriodArray || [];
      const tokenCounts = {};

      // Aggregate total counts for each token
      rawData.forEach((item) => {
        const { code } = item._id;
        const count = item.count;
        if (!tokenCounts[code]) {
          tokenCounts[code] = 0;
        }
        tokenCounts[code] += count;
      });

      // Get the top 5 tokens by count
      const top5Tokens = Object.keys(tokenCounts)
        .sort((a, b) => tokenCounts[b] - tokenCounts[a])
        .slice(0, 5);

      const tokensData = {};

      // Process raw data to structure it for Chart.js
      rawData.forEach((item) => {
        const { code } = item._id;
        const count = item.count;
        let periodName;

        if (time === TIME_PERIOD.MONTHLY) {
          // Monthly data
          const { month } = item._id;
          periodName = monthNames[month - 1];
        } else if (time === TIME_PERIOD.QUARTERLY) {
          // Quarterly data
          const { quarter } = item._id;
          periodName = quarterNames[quarter - 1];
        } else if (time === TIME_PERIOD.ANNUALLY) {
          // Yearly data
          const { year } = item._id;
          periodName = year.toString();
        }

        if (top5Tokens.includes(code)) {
          if (!tokensData[code]) {
            tokensData[code] = {};
          }
          tokensData[code][periodName] = count;
        }
      });

      // Fill missing periods with 0
      Object.keys(tokensData).forEach((token) => {
        timePeriodArray.forEach((period) => {
          if (!tokensData[token][period]) {
            tokensData[token][period] = 0;
          }
        });
      });

      setTimeLabels(timePeriodArray);
      setPaymentLinkChart(tokensData);
      console.log("tokensData", tokensData);
    } catch (error) {
      console.error("Error fetching payment links:", error);
    }
  };

  const fetchCryptoMargins = async (time) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/payment-link/crypto-margins`,
        { timePeriod: time }
      );
      console.log("cryptoMargins", response?.data?.data);

      if (response?.data) {
        const rawData = response?.data?.data || [];
        const tokenCounts = {};

        // Aggregate total counts for each token and period
        rawData.forEach((item) => {
          const tokenData = item.tokenCounts;
          const periodName = item.period;

          for (const token in tokenData) {
            if (!tokenCounts[token]) {
              tokenCounts[token] = {};
            }
            if (!tokenCounts[token][periodName]) {
              tokenCounts[token][periodName] = 0;
            }
            tokenCounts[token][periodName] += tokenData[token];
          }
        });

        // Fill missing periods with 0
        Object.keys(tokenCounts).forEach((token) => {
          response?.data?.periodNames.forEach((period) => {
            if (!tokenCounts[token][period]) {
              tokenCounts[token][period] = 0;
            }
          });
        });

        setCryptoMarginsChart(tokenCounts);
        setTimeLabels(response?.data?.periodNames);
      }
    } catch (error) {
      console.error("Error fetching crypto margins:", error);
    }
  };

  const getMerchantSymbols = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/payment-link/crypto-symbols`
      );
      console.log("cryptoSymbols", response?.data);
      setSelectCoin(response?.data);

      // Set the first value of the response to setGraphCoin
      const firstSymbol = Object.values(response?.data)[0];
      if (graphCoin === null) {
        setGraphCoin(firstSymbol);
      }
    } catch (error) {
      console.log("error in getSymbol API : ", error);
    }
  };

  const fetchGraph4MerchantDepositWithdrawals = async (time) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/payment-link/merchant-depositWithdraw`,
        {
          timePeriod: chartTime,
          symbol: graphCoin,
        }
      );
      if (response?.data?.deposits) {
        const rawData = response?.data?.deposits || [];
        setMerchantDeposits(rawData);
      }
      if (response?.data?.withdrawals) {
        const rawData = response?.data?.withdrawals || [];
        setMerchantWithdrawals(rawData);
      }
      setTimeLabels(response?.data?.periodNames);
    } catch (error) {
      console.log("Error fetching merchant deposit/withdrawals:", error);
    }
  };

  const fetchActivePaymentLinks = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/payment-link/merchant-paymentLink`
      );
      console.log("resp", response);
      setActivePaymentLinks(response?.data);
    } catch (error) {
      console.log("Error fetching payment links:", error);
    }
  };

  const fetchActiveMerchantApps = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/payment-link/merchant-apps`
      );
      setActiveMerchantApps(response?.data);
    } catch (error) {
      console.log("Error fetching merchant apps:", error);
    }
  };

  const exportMultipleChartsToPdf = async () => {
    const allChartsDiv = document.getElementById("all-chart");

    if (!allChartsDiv) {
      console.error("No charts found");
      return;
    }

    // Create jsPDF instance in landscape mode
    const pdf = new jsPDF("landscape", "pt", "a4");

    // Use html2canvas to render the entire div to a canvas with a lower scale for smaller size
    const canvas = await html2canvas(allChartsDiv, { scale: 1.5 }); // Lower scale for smaller size
    const imgData = canvas.toDataURL("image/jpeg", 0.5); // Use JPEG format with compression

    // Get pdf dimensions
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Calculate aspect ratio
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    const widthRatio = pdfWidth / canvasWidth;
    const heightRatio = pdfHeight / canvasHeight;
    const ratio = Math.min(widthRatio, heightRatio);

    const imgWidth = canvasWidth * ratio;
    const imgHeight = canvasHeight * ratio;

    const xOffset = (pdfWidth - imgWidth) / 2;
    const yOffset = (pdfHeight - imgHeight) / 2;

    // Add the image to the PDF
    pdf.addImage(imgData, "JPEG", xOffset, yOffset, imgWidth, imgHeight);

    // Download the PDF
    pdf.save("Chart-Report.pdf");
  };

  useEffect(() => {
    getMerchantSymbols();
    fetchPaymentLinkCount(chartTime);
    fetchRevenueChart(chartTime);
    fetchCryptoMargins(chartTime);
    fetchGraph4MerchantDepositWithdrawals(chartTime);
    fetchActivePaymentLinks();
    fetchActiveMerchantApps();
  }, [chartTime]);

  useEffect(() => {
    getMerchantSymbols();
    fetchRevenueChart(chartTime);
    fetchGraph4MerchantDepositWithdrawals(chartTime);
  }, [graphCoin]);

  useEffect(() => {
    const colors = [
      "rgb(54,162,235)", // ETH
      "rgb(246,99,132)", // BNB
      "rgb(248,159,64)", // USDT.*
      "rgb(251,205,86)",
      "rgb(75,192,192)",
      "rgb(255,99,132)",
      "rgb(153,102,255)",
      "rgb(255,159,64)",
      "rgb(54,162,145)",
      "rgb(104,162,235)",
      "rgb(164,162,235)",
      "rgb(204,162,235)",
      "rgb(144,162,235)",
    ];

    const createDataset = (token, index) => ({
      label: token,
      data: timeLabels?.map((period) =>
        paymentLinkChart[token] ? paymentLinkChart?.[token][period] : 0
      ),
      borderColor: colors[index % colors.length],
      backgroundColor: "white",
    });

    let datasets;
    if (Object.keys(paymentLinkChart).length === 0) {
      paymentLinkChart = { "NO DATA AVAILABLE": timeLabels };
      datasets = Object.keys(paymentLinkChart).map(createDataset ?? 0);
    } else if (Object.keys(paymentLinkChart).length > 0) {
      datasets = Object.keys(paymentLinkChart).map(createDataset ?? 0);
    }

    console.log("datasets----- : ", datasets);

    const DD = datasets.map((x) => {
      return {
        ...x,
        tension: 0.5,
        fill: false,
        borderWidth: 2,
      };
    });

    const data = {
      labels: timeLabels,
      datasets: DD,
    };

    // const datasetsWithTension = datasets.map((dataset) => ({
    //   ...dataset,
    //   // tension: 0.5, // Add smooth curves
    // }));

    // const data = {
    //   labels: months, // Set the months as the X-axis labels
    //   datasets: [
    //     {
    //       label: "USD",
    //       data: totalPrices, // Set the totalPrices as the data for the chart
    //       borderColor: "#f1d777", // Line color
    //       backgroundColor: gradient, // Gradient for the area
    //       tension: 0.5, // Smooth curves
    //       fill: true, // Enable the area fill
    //       borderWidth: 2.5, // Thicker line width
    //     },
    //   ],
    // };

    const config = {
      type: "line",
      data: data,

      options: {
        responsive: true,

        maintainAspectRatio: false, // Allow custom height/width
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Payment Links (by counts)",
          },
        },
      },
    };

    const destroyChart = (chartRef) => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };

    destroyChart(chartRef1);
    chartRef1.current = new Chart(document.getElementById("lineChart"), config);
  }, [paymentLinkChart]);

  // Graph 2 Use Effects
  // useEffect(() => {
  //   if (!timeLabels) {
  //     return;
  //   }
  //   console.log("revenueChart?.paymentLinks : ", revenueChart);

  //   const paymentLinksData = Object.keys(timeLabels).map(
  //     (month) => revenueChart?.paymentLinks?.[month]?.total
  //   );

  //   console.log(
  //     "-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- : ",
  //     paymentLinksData
  //   );

  //   const merchantTxsData = Object.keys(timeLabels).map(
  //     (month) => revenueChart?.merchant?.[month]?.total
  //   );

  //   console.log(
  //     "-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- : ",
  //     merchantTxsData
  //   );

  //   const graph2Data = {
  //     labels: timeLabels,
  //     datasets: [
  //       {
  //         label: "Payment links admin fee",
  //         data: paymentLinksData,
  //         borderColor: "rgb(246,99,132)",
  //         backgroundColor: "rgb(246,99,132)",

  //         type: "bar",
  //       },
  //       {
  //         label: "Merchant txs admin fee",
  //         data: merchantTxsData,
  //         borderColor: "rgb(54,162,235)",
  //         backgroundColor: "rgb(54,162,235)",
  //         stack: "bar",
  //       },
  //     ],
  //   };

  //   const graph2Config = {
  //     type: "bar",
  //     data: graph2Data,
  //     options: {
  //       responsive: true,
  //       plugins: {
  //         legend: {
  //           position: "top",
  //         },
  //         title: {
  //           display: true,
  //           text: `Merchant & payment links revenue (for ${graphCoin})`,
  //         },
  //       },
  //     },
  //   };

  //   const destroyChart = (chartRef) => {
  //     if (chartRef.current) {
  //       chartRef.current.destroy();
  //       chartRef.current = null;
  //     }
  //   };

  //   destroyChart(chartRef2);
  //   chartRef2.current = new Chart(
  //     document.getElementById("stacked-bar"),
  //     graph2Config
  //   );
  // }, [revenueChart, timeLabels]);

  useEffect(() => {
    if (!timeLabels) {
      return;
    }
    console.log("revenueChart?.paymentLinks : ", revenueChart);

    // Format data to 8 decimal places
    const paymentLinksData = Object.keys(timeLabels).map((month) =>
      revenueChart?.paymentLinks?.[month]?.total
        ? parseFloat(revenueChart?.paymentLinks?.[month]?.total).toFixed(8)
        : "0.00000000"
    );

    console.log(
      "-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- : ",
      paymentLinksData
    );

    const merchantTxsData = Object.keys(timeLabels).map((month) =>
      revenueChart?.merchant?.[month]?.total
        ? parseFloat(revenueChart?.merchant?.[month]?.total).toFixed(8)
        : "0.00000000"
    );

    console.log(
      "-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- : ",
      merchantTxsData
    );

    const graph2Data = {
      labels: timeLabels,
      datasets: [
        {
          label: "Payment links admin fee",
          data: paymentLinksData,
          borderColor: "rgb(246,99,132)",
          backgroundColor: "rgb(246,99,132)",
          type: "bar",
        },
        {
          label: "Merchant txs admin fee",
          data: merchantTxsData,
          borderColor: "rgb(54,162,235)",
          backgroundColor: "rgb(54,162,235)",
          stack: "bar",
        },
      ],
    };

    const graph2Config = {
      type: "bar",
      data: graph2Data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: `Merchant & payment links revenue (for ${graphCoin})`,
          },
        },
      },
    };

    const destroyChart = (chartRef) => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };

    destroyChart(chartRef2);
    chartRef2.current = new Chart(
      document.getElementById("stacked-bar"),
      graph2Config
    );
  }, [revenueChart, timeLabels]);

  useEffect(() => {
    const colors = [
      "rgb(54,162,235)", // ETH
      "rgb(246,99,132)", // BNB
      "rgb(248,159,64)", // USDT
      "rgb(251,205,86)", // USDC
      "rgb(75,192,192)",
      "rgb(255,99,132)",
      "rgb(153,102,255)",
      "rgb(255,159,64)",
      "rgb(54,162,145)",
      "rgb(104,162,235)",
      "rgb(164,162,235)",
      "rgb(204,162,235)",
    ];

    const filteredCryptoMarginsChart = Object.keys(cryptoMarginsChart).reduce(
      (acc, token) => {
        if (token !== "undefined") {
          acc[token] = cryptoMarginsChart[token];
        }
        return acc;
      },
      {}
    );

    const datasets = Object.keys(filteredCryptoMarginsChart).map(
      (token, index) => {
        return {
          label: token, // Use the token name as the label
          data: timeLabels?.map(
            (period) => filteredCryptoMarginsChart?.[token][period] || 0
          ),
          backgroundColor: colors[index % colors.length],
        };
      }
    );

    const data = {
      labels: timeLabels,
      datasets: datasets,
    };

    const config = {
      type: "doughnut",
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
            labels: {
              generateLabels: function (chart) {
                const original =
                  Chart.defaults.plugins.legend.labels.generateLabels;
                const labels = original.call(this, chart);

                labels.forEach((label) => {
                  const tokenIndex = datasets.findIndex(
                    (dataset) => dataset.label === label.text
                  );
                  if (tokenIndex >= 0) {
                    label.fillStyle = colors[tokenIndex % colors.length]; // Set the label color
                  }
                });
                return labels;
              },
            },
          },
          title: {
            display: true,
            text: "Top 5 Crypto Margins (by counts)",
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const token = context.dataset.label;
                const count = context.raw;
                return `${token}: ${count}`;
              },
            },
          },
        },
      },
    };

    const destroyChart = (chartRef) => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };

    destroyChart(chartRef3);
    chartRef3.current = new Chart(
      document.getElementById("doughnutChart"),
      config
    );
  }, [cryptoMarginsChart]);

  // Graph 4 Useeffects
  useEffect(() => {
    if (!merchantDeposits || !merchantWithdrawals || !timeLabels) {
      return;
    }

    const processedDeposit = Object.keys(timeLabels).map(
      (month) => merchantDeposits?.[month]?.total || 0
    );
    const processedWithdrawal = Object.keys(timeLabels).map(
      (month) => merchantWithdrawals?.[month]?.total || 0
    );

    const graph4data = {
      labels: timeLabels,
      datasets: [
        {
          label: "Deposits",
          data: processedDeposit,
          borderColor: "rgb(246,99,132)",
          backgroundColor: "rgb(246,99,132)",
        },
        {
          label: "Withdrawals",
          data: processedWithdrawal,
          borderColor: "rgb(54,162,235)",
          backgroundColor: "rgb(54,162,235)",
        },
      ],
    };

    const graph4Config = {
      type: "bar",
      data: graph4data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: `Merchant Deposits/Withdrawals (for ${graphCoin})`,
          },
        },
      },
    };

    const destroyChart = (chartRef) => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };

    destroyChart(chartRef4);
    chartRef4.current = new Chart(
      document.getElementById("vertical-chart-graph4"),
      graph4Config
    );
  }, [merchantDeposits, merchantWithdrawals, timeLabels]);

  return (
    <div>
      <div className="content-header">
        <section className="content">
          <div className="card-footer box-container">
            <div className="row">
              <div className="col-sm-3 col-6">
                <div className="description-block border-right">
                  <h5 className="description-header">{props.walletUsers}</h5>
                  <span className="description-text">Total Wallet Users</span>
                </div>
              </div>
              <div className="col-sm-3 col-6">
                <div className="description-block border-right">
                  <h5 className="description-header">{props.merchantCount}</h5>
                  <span className="description-text">Total Merchants</span>
                </div>
              </div>
              <div className="col-sm-3 col-6">
                <div className="description-block border-right">
                  <h5 className="description-header">{activePaymentLinks}</h5>
                  <span className="description-text">Active Payment Links</span>
                </div>
              </div>
              <div className="col-sm-3 col-6">
                <div className="description-block">
                  <h5 className="description-header">{activeMerchantApps}</h5>
                  <span className="description-text">Active Merchant Apps</span>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2 report-container">
              <div>
                <button
                  type="button"
                  className="btn btn-primary mr-3"
                  onClick={exportMultipleChartsToPdf}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span style={{ margin: "0 5px" }}>Report</span>{" "}
                  <HiOutlineDownload />
                </button>
              </div>
              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <label className="btn btn-secondary">
                  <input
                    onClick={() => setChartTime(TIME_PERIOD.MONTHLY)}
                    type="radio"
                    name="options"
                    id="option3"
                    autoComplete="off"
                  />{" "}
                  MONTHLY
                </label>
                <label className="btn btn-secondary">
                  <input
                    onClick={() => setChartTime(TIME_PERIOD.QUARTERLY)}
                    type="radio"
                    name="options"
                    id="option3"
                    autoComplete="off"
                  />{" "}
                  QUARTERLY
                </label>
                <label className="btn btn-secondary">
                  <input
                    onClick={() => setChartTime(TIME_PERIOD.ANNUALLY)}
                    type="radio"
                    name="options"
                    id="option3"
                    autoComplete="off"
                  />{" "}
                  YEARLY
                </label>
              </div>
            </div>
          </div>
          <div className="container-fluid main-diagram" id="all-chart">
            <div className="row mb-2 diagram-parent">
              <div className="col-sm-6 chart-parent line-chart">
                <canvas id="lineChart"></canvas>
              </div>

              <div className="col-sm-6 chart-parent line-chart doughnut-chart">
                <canvas id="doughnutChart"></canvas>
              </div>
            </div>

            <div
              style={{
                backgroundColor: "rgba(219, 224, 228, 1)",
                // backgroundColor: "rgba(195, 198, 204, 1)",
              }}
            >
              {/* button  */}
              <div className="container-fluid">
                <div className="row mb-2 report-container">
                  <div className="btn-group btn-group-toggle">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginLeft: "15px",
                        paddingTop: "6px",
                      }}
                    >
                      <select
                        value={dropdownValue}
                        onChange={handleDropdownChange}
                        className="form-select btn-primary"
                        style={{
                          border: "1px solid none",
                          borderRadius: "5px",
                          paddingRight: "12px",
                          paddingLeft: "12px",
                          paddingTop: "6px",
                          paddingBottom: "6px",
                        }}
                      >
                        {Object.entries(selectCoin).map(
                          ([key, value], index) => (
                            <option key={index} value={key}>
                              {value}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {/* Below two graphs (2 and 4th) */}
              <div className="row mb-2 diagram-parent">
                <div className="col-sm-6 chart-parent">
                  <canvas id="stacked-bar"></canvas>
                </div>

                <div className="col-sm-6 chart-parent">
                  <canvas id="vertical-chart-graph4"></canvas>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Reports;
