import React, { useState } from "react";
import { Link } from "react-router-dom";
import CustomPagination from "../../components/Pagination";
import moment from "moment/moment";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

const CustomTable = ({ apiResult, timeFormat }) => {
  // State variables
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of rows per page

  const totalPages = Math.ceil(apiResult?.data?.length / itemsPerPage);

  const filteredData = apiResult?.data?.filter((item) => {
    if (timeFormat === "MONTHS" || timeFormat === "YEARS") {
      console.log(55555);

      return (
        item?.month?.toString().includes(searchQuery) ||
        item?.year?.toString().includes(searchQuery)
      );
    } else if (timeFormat === "WEEKS") {
      console.log(9999966666);
      if (timeFormat === "WEEKS") {
        return (
          item?.weekStart?.includes(searchQuery) ||
          item?.weekEnd?.includes(searchQuery)
        );
      }
    } else if (timeFormat === "DAYS") {
      console.log(3333);

      return item?.date?.includes(searchQuery);
    }
    return false;
  });

  console.log("filteredData123", filteredData);
  console.log("apiResult0202", apiResult);

  const paginatedData = filteredData?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to the first page after a search
  };

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Export functions

  const downloadCSV = () => {
    if (!filteredData || filteredData.length === 0) {
      toast.error("No data available to export.");
      return;
    }

    // Create headers dynamically
    const headers = [];
    if (filteredData.some((item) => item?.count !== undefined)) {
      headers.push("Count");
    }
    if (filteredData.some((item) => item?.adminFee !== undefined)) {
      headers.push("Admin Fee");
    }

    if (timeFormat === "MONTHS") headers.push("Month");
    if (timeFormat === "MONTHS" || timeFormat === "YEARS") headers.push("Year");
    if (timeFormat === "WEEKS") headers.push("Week Start", "Week End");
    if (timeFormat === "DAYS") headers.push("Date");

    // Map the data rows dynamically
    const rows = filteredData.map((item) => {
      const row = [];
      if (item?.count !== undefined) row.push(item?.count);
      if (item?.adminFee !== undefined) row.push(item?.adminFee);
      if (timeFormat === "MONTHS") row.push(item?.month);
      if (timeFormat === "MONTHS" || timeFormat === "YEARS")
        row.push(item.year);
      if (timeFormat === "WEEKS") {
        row.push(
          moment(item?.weekStart).format("DD/MM/YYYY"),
          moment(item?.weekEnd).format("DD/MM/YYYY")
        );
      }
      if (timeFormat === "DAYS") {
        row.push(moment(item?.date).format("DD/MM/YYYY"));
      }
      return row;
    });

    // Combine headers and rows into CSV format
    const csvContent =
      "data:text/csv;charset=utf-8," +
      [headers, ...rows].map((row) => row.join(",")).join("\n");

    // Create and trigger the download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "table_data.csv");
    document.body.appendChild(link);
    link.click();
  };

  const downloadExcel = () => {
    if (!filteredData || filteredData.length === 0) {
      toast.error("No data available to export.");
      return;
    }

    // Create headers dynamically
    const headers = [];
    if (filteredData.some((item) => item?.count !== undefined)) {
      headers.push("Count");
    }
    if (filteredData.some((item) => item?.adminFee !== undefined)) {
      headers.push("Admin Fee");
    }

    if (timeFormat === "MONTHS") headers.push("Month");
    if (timeFormat === "MONTHS" || timeFormat === "YEARS") headers.push("Year");
    if (timeFormat === "WEEKS") headers.push("Week Start", "Week End");
    if (timeFormat === "DAYS") headers.push("Date");

    const rows = filteredData.map((item) => {
      const row = [];
      if (item?.count !== undefined) row.push(item?.count);
      if (item?.adminFee !== undefined) row.push(item?.adminFee);
      if (timeFormat === "MONTHS") row.push(item?.month);
      if (timeFormat === "MONTHS" || timeFormat === "YEARS")
        row.push(item.year);
      if (timeFormat === "WEEKS") {
        row.push(
          moment(item?.weekStart).format("DD/MM/YYYY"),
          moment(item?.weekEnd).format("DD/MM/YYYY")
        );
      }
      if (timeFormat === "DAYS") {
        row.push(moment(item?.date).format("DD/MM/YYYY"));
      }
      return row;
    });

    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, "table_data.xlsx");
  };

  const downloadPDF = () => {
    if (!filteredData || filteredData.length === 0) {
      toast.error("No data available to export.");
      return;
    }

    // Create headers dynamically
    const headers = [];
    if (filteredData.some((item) => item?.count !== undefined)) {
      headers.push("Count");
    }
    if (filteredData.some((item) => item?.adminFee !== undefined)) {
      headers.push("Admin Fee");
    }

    if (timeFormat === "MONTHS") headers.push("Month");
    if (timeFormat === "MONTHS" || timeFormat === "YEARS") headers.push("Year");
    if (timeFormat === "WEEKS") headers.push("Week Start", "Week End");
    if (timeFormat === "DAYS") headers.push("Date");

    const rows = filteredData.map((item) => {
      const row = [];
      if (item?.count !== undefined) row.push(item?.count);
      if (item?.adminFee !== undefined) row.push(item?.adminFee);
      if (timeFormat === "MONTHS") row.push(item?.month);
      if (timeFormat === "MONTHS" || timeFormat === "YEARS")
        row.push(item.year);
      if (timeFormat === "WEEKS") {
        row.push(
          moment(item?.weekStart).format("DD/MM/YYYY"),
          moment(item?.weekEnd).format("DD/MM/YYYY")
        );
      }
      if (timeFormat === "DAYS") {
        row.push(moment(item?.date).format("DD/MM/YYYY"));
      }
      return row;
    });

    const doc = new jsPDF();
    doc.autoTable({
      head: [headers],
      body: rows,
    });

    doc.save("table_data.pdf");
  };

  console.log("timeFormat:", timeFormat);
  console.log("apiResult?.data:", apiResult?.data);
  console.log("searchQuery:", searchQuery);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="form-control w-50"
                />
                <div>
                  <button
                    className="btn btn-primary mx-1"
                    onClick={downloadCSV}
                  >
                    Export CSV
                  </button>
                  <button
                    className="btn btn-success mx-1"
                    onClick={downloadExcel}
                  >
                    Export Excel
                  </button>
                  <button className="btn btn-danger mx-1" onClick={downloadPDF}>
                    Export PDF
                  </button>
                </div>
              </div>

              <table className="table">
                <thead>
                  <tr>
                    <th>
                      {paginatedData?.[0]?.count !== undefined
                        ? "Count"
                        : "Admin Fee"}
                    </th>
                    {timeFormat === "MONTHS" && <th>Month</th>}
                    {(timeFormat === "MONTHS" || timeFormat === "YEARS") && (
                      <th>Year</th>
                    )}
                    {timeFormat === "WEEKS" && (
                      <>
                        <th>Week Start</th>
                        <th>Week End</th>
                      </>
                    )}
                    {timeFormat === "DAYS" && <th>Date</th>}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.length > 0 ? (
                    paginatedData.map((item, index) => (
                      <tr key={index}>
                        <td>{item?.count || item?.adminFee}</td>
                        {timeFormat === "MONTHS" && <td>{item?.month}</td>}
                        {(timeFormat === "MONTHS" ||
                          timeFormat === "YEARS") && <td>{item?.year}</td>}
                        {timeFormat === "WEEKS" && (
                          <>
                            <td>
                              {moment(item?.weekStart).format("DD/MM/YYYY")}
                            </td>
                            <td>
                              {moment(item?.weekEnd).format("DD/MM/YYYY")}
                            </td>
                          </>
                        )}
                        {timeFormat === "DAYS" && (
                          <td>{moment(item?.date).format("DD/MM/YYYY")}</td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            marginTop: "20px",
                          }}
                        >
                          <p>Data not found</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          {totalPages > 1 && (
            <CustomPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomTable;
