import { toast } from "react-toastify";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import { isValidAddress } from "./evm.helper";
import { validate } from "bitcoin-address-validation";
import axios from "axios";
import {
  BNB_ADDRESS_SCAN_URL,
  BNB_CHAIN_ID,
  BTC_ADDRESS_SCAN_URL,
  BTC_CHAIN_ID,
  ETH_ADDRESS_SCAN_URL,
  ETH_CHAIN_ID,
  POLYGON_ADDRESS_SCAN_URL,
  POLYGON_CHAIN_ID,
  TRON_ADDRESS_SCAN_URL,
  TRON_CHAIN_ID,
} from "./constant";

export function fromWeiCustom(balance, decimals) {
  const balanceInStr = balance.toString();
  const decimal = Number(decimals);

  // If the balance length is less than or equal to the number of decimals, prepend zeros
  if (balanceInStr.length <= decimal) {
    const zeros = "0.".padEnd(decimal - balanceInStr.length + 2, "0");
    return parseFloat(zeros + balanceInStr);
  }

  // Insert the decimal point at the correct position
  const integerPart = balanceInStr.substring(0, balanceInStr.length - decimal);
  const fractionalPart = balanceInStr.substring(balanceInStr.length - decimal);

  // Return the formatted amount
  return parseFloat(integerPart + "." + fractionalPart);
}

// export function fromWeiCustom(balance, decimals) {
//   const balanceInStr = balance.toString();
//   const decimal = Number(decimals);

//   // If the balance length is less than or equal to the number of decimals, prepend zeros
//   if (balanceInStr.length <= decimal) {
//     const zeros = "0.".padEnd(decimal - balanceInStr.length + 2, "0");
//     return parseFloat(zeros + balanceInStr);
//   }

//   // Insert the decimal point at the correct position
//   const integerPart = balanceInStr.substring(0, balanceInStr.length - decimal);
//   const fractionalPart = balanceInStr.substring(balanceInStr.length - decimal);

//   // Return the formatted amount
//   return parseFloat(integerPart + "." + fractionalPart);
// }

export const formatNumber = (input, decimalPlace) => {
  if (!(input && decimalPlace)) {
    return 0;
  }
  let str = input.toString();
  let dotIndex = str.indexOf(".");
  if (dotIndex === -1) {
    return str;
  }
  let preDot = str.substring(0, dotIndex + 1);
  let postDot = str.substring(dotIndex + 1, dotIndex + decimalPlace + 1);

  return preDot + postDot;
};

export const trimAddress = (address, firstChar, lastChar) => {
  if (!address || typeof address !== "string") {
    return "";
  }

  if (address.length <= firstChar + lastChar) {
    return address;
  } else {
    return address.slice(0, firstChar) + "..." + address.slice(-lastChar);
  }
};

export const formatDate = (dateString) => {
  // Convert the date string to a Date object
  const date = new Date(dateString);

  // Array of month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get day, month, year, hours, and minutes
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Format the date in "DD-Mon-YYYY hh:mmAM/PM" format
  const formattedDate = `${day}-${monthNames[monthIndex]}-${year} ${
    hours % 12 || 12
  }:${minutes < 10 ? "0" + minutes : minutes}${hours >= 12 ? " PM" : " AM"}`;

  return formattedDate;
};

export function getNetwork(chainId) {
  switch (chainId) {
    case 11155111:
      return {
        network: "Ethereum",
        symbol: "ETH",
        rpc: "https://eth-sepolia.g.alchemy.com/v2/HZGTgTPiqB408bYkAdUFeDOTedqp4DBA",
      };
    case 80002:
      return {
        network: "Polygon",
        symbol: "MATIC",
        rpc: "https://polygon-amoy.g.alchemy.com/v2/HZGTgTPiqB408bYkAdUFeDOTedqp4DBA",
      };
    case 97:
      return {
        network: "Binance Smart Chain",
        symbol: "BNB",
        rpc: "https://data-seed-prebsc-1-s1.bnbchain.org:8545",
      };
    case 43113:
      return {
        network: "Avalanche",
        symbol: "AVAX",
        rpc: "https://avax-fuji.g.alchemy.com/v2/HZGTgTPiqB408bYkAdUFeDOTedqp4DBA",
      };
    default:
      throw new Error("Unsupported chainId");
  }
}

export const copyToClipboard = (address) => {
  if (address) {
    navigator?.clipboard
      ?.writeText(address)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch((error) => {
        toast.error("Failed to copy address.");
        console.error("Error copying to clipboard:", error);
      });
  } else {
    toast.warning("No address available to copy.");
  }
};

export const exportToPDF = (data) => {
  const doc = new jsPDF();
  autoTable(doc, {
    head: [
      [
        "S.N.",
        "Name",
        "Email",
        "PlatformName",
        "PlatformCategory",
        "ContactNumber",
        // "Location",
        "Description",
      ],
    ],
    body: data.map((user, index) => [
      index + 1,
      user?.name || "",
      user?.email || "",
      user?.platformName || "",
      user?.platformCategory || "",
      user?.contactNumber || "",
      // user?.location || "",
      user?.description || "",
    ]),
  });
  doc.save("users.pdf");
};

export const exportToExcel = (data) => {
  const worksheet = XLSX.utils.json_to_sheet(
    data.map((user, index) => ({
      S_N: index + 1,
      Name: user?.name || "",
      Email: user?.email || "",
      PlatformName: user?.platformName || "",
      PlatformCategory: user?.platformCategory || "",
      ContactNumber: user?.contactNumber || "",
      Location: user?.location || "",
      Description: user?.description || "",
    }))
  );
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
  XLSX.writeFile(workbook, "users.xlsx");
};

export const getValidateTronAddress = async (address) => {
  try {
    // Make the async call to the API for Tron address validation
    const url = `${process.env.REACT_APP_API_URL}/token/validate-tron-address`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: { address: address },
    });

    return response?.data?.isValid;
  } catch (error) {
    return false;
  }
};

export const ValidateInputs = async (name, value, errors) => {
  const newErrors = { ...errors };

  let isValidTronAddress = false;
  if (name === "tronAdminWallet") {
    isValidTronAddress = await getValidateTronAddress(value);
  }

  const isValidEVMAddress = await isValidAddress(value);

  const isValidBtcAddress = validate(value);

  switch (name) {
    case "name":
      newErrors.name = !value.trim() ? "Name is required" : "";
      break;
    case "email":
      newErrors.email = !value.trim()
        ? "Email is required"
        : !/\S+@\S+\.\S+/.test(value)
        ? "Email is invalid"
        : "";
      break;
    case "contactNumber":
      newErrors.contactNumber = !value
        ? "Contact number is required"
        : !/^\d{10}$/.test(value)
        ? "Invalid contact number"
        : "";
      break;
    case "platformName":
      newErrors.platformName = !value.trim() ? "Platform name is required" : "";
      break;
    case "platformCategory":
      newErrors.platformCategory = !value.trim()
        ? "Platform category is required"
        : "";
      break;
    case "location":
      newErrors.location = !value.trim() ? "Location is required" : "";
      break;
    case "description":
      newErrors.description = !value.trim() ? "Description is required" : "";
      break;
    case "uniqueID":
      newErrors.uniqueID = !value ? "Password is required" : "";
      break;
    case "question":
      newErrors.question = !value.trim() ? "Question is required" : "";
      break;
    case "answer":
      newErrors.answer = !value.trim() ? "Answer is required" : "";
      break;

    case "platformFee":
      newErrors.platformFee = !value.trim()
        ? "Platform fee is required"
        : isNaN(value) || Number(value) < 0 || Number(value) > 50
        ? "Platform Fee must be a positive number between 0 and 50"
        : "";
      break;

    case "merchantFee":
      newErrors.merchantFee = !value.trim()
        ? "Merchant fee is required"
        : isNaN(value) || Number(value) < 0 || Number(value) > 50
        ? "Merchant Fee must be a positive number between 0 and 50"
        : "";
      break;

    case "tronPlatformFee":
      newErrors.tronPlatformFee = !value.trim()
        ? "Platform fee is required"
        : isNaN(value) || Number(value) < 0 || Number(value) > 50
        ? "Platform Fee must be a positive number between 0 and 50"
        : "";
      break;

    case "tronMerchantFee":
      newErrors.tronMerchantFee = !value.trim()
        ? "Merchant fee is required"
        : isNaN(value) || Number(value) < 0 || Number(value) > 50
        ? "Merchant Fee must be a positive number between 0 and 50"
        : "";
      break;

    case "btcPlatformFee":
      newErrors.btcPlatformFee = !value.trim()
        ? "Platform fee is required"
        : isNaN(value) || Number(value) < 0 || Number(value) > 50
        ? "Platform Fee must be a positive number between 0 and 50"
        : "";
      break;

    case "btcMerchantFee":
      newErrors.btcMerchantFee = !value.trim()
        ? "Merchant fee is required"
        : isNaN(value) || Number(value) < 0 || Number(value) > 50
        ? "Merchant Fee must be a positive number between 0 and 50"
        : "";
      break;

    case "adminWallet":
      newErrors.adminWallet = !value.trim()
        ? "EVM admin wallet is required"
        : !isValidEVMAddress
        ? "Invalid admin wallet address"
        : "";
      break;

    case "tronAdminWallet":
      newErrors.tronAdminWallet = !value.trim()
        ? "Tron admin wallet is required"
        : !isValidTronAddress
        ? "Invalid wallet address"
        : "";
      break;

    case "btcAdminWallet":
      newErrors.btcAdminWallet = !value.trim()
        ? "BTC admin wallet is required"
        : !isValidBtcAddress
        ? "Invalid wallet address"
        : "";
      break;

    default:
      break;
  }

  return newErrors;
};

export const getAddressLink = (chainId, txHash) => {
  switch (chainId) {
    case ETH_CHAIN_ID:
      return ETH_ADDRESS_SCAN_URL + txHash;
    case POLYGON_CHAIN_ID:
      return POLYGON_ADDRESS_SCAN_URL + txHash;
    case BNB_CHAIN_ID:
      return BNB_ADDRESS_SCAN_URL + txHash;
    case TRON_CHAIN_ID:
      return TRON_ADDRESS_SCAN_URL + txHash;
    case BTC_CHAIN_ID:
      return BTC_ADDRESS_SCAN_URL + txHash;
    // case "43113":
    //   return `https://testnet.avascan.info/blockchain/c/address/${txHash}`;
    default:
      throw new Error("Unsupported chainId in getTxLink function");
  }
};

export const ChainIdEnum = {
  BINANCE_SMART_CHAIN: "97",
  POLYGON: "80002",
  ETHEREUM: "11155111",
  TRON: "TRON",
  BITCOIN: "BTC",
};

export const NetworkEnum = {
  BINANCE_SMART_CHAIN: "BINANCE SMART CHAIN",
  POLYGON: "POLYGON",
  ETHEREUM: "ETHEREUM",
  TRON: "TRON",
  BITCOIN: "BITCOIN",
};

export const TIME_PERIOD = {
  MONTHLY: "MONTHLY",
  QUARTERLY: "QUARTERLY",
  ANNUALLY: "ANNUALLY",
};

export const exportToCSV = (data, headers, filename = "data.csv") => {
  // Convert headers to a CSV row
  const headerRow = headers.map((header) => header.label).join(",");

  // Map data to rows based on headers
  const csvRows = data.map((row) =>
    headers.map((header) => row[header.key] || "").join(",")
  );

  // Combine headers and rows
  const csvContent = [headerRow, ...csvRows].join("\n");

  // Create a Blob and download the file
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", filename);
  link.style.visibility = "hidden";

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const remove_p_br_Tags = (text) => {
  console.log("text : == ", text);

  if (!text) {
    return text;
  }
  // Remove <p> and </p> tags
  let result = text.replace(/<\/?p>/gi, "");
  // Remove <br> tags
  result = result.replace(/<br\s*\/?>/gi, "");
  // Remove <li> tags
  result = result.replace(/<li\s*\/?>/gi, "");
  // Remove <span> tags
  result = result.replace(/<\/?span.*?>/gi, "");
  return result;
};

export const getListErrors = (error) => {
  if (Array.isArray(error) && error.length > 0) {
    return error[0]; // Return the first element if `error` is a non-empty array
  }
  return error; // Return the entire `error` otherwise
};

export const validateForm = async (formData) => {
  const newErrors = {};

  if (!formData?.address?.trim()) {
    newErrors.address = "Address is required";
  }

  // const isEvmAddressCondition = formData.address.length === 42 && formData.address[0] === "0" && formData.address[1] === "x";
  // const isTronAddressCondition = formData.address.length === 34 && formData.address[0] === "T";

  const isEVMNetworkCondition =
    formData?.network === NetworkEnum.POLYGON ||
    formData?.network === NetworkEnum.BINANCE_SMART_CHAIN ||
    formData?.network === NetworkEnum.ETHEREUM;
  const isTronNetworkCondition = formData?.network === NetworkEnum.TRON;
  const isBTCNetworkCondition = formData?.network === NetworkEnum.BITCOIN;

  const isEvmAddress = await isValidAddress(formData?.address);
  const isTronAddress = await getValidateTronAddress(formData?.address);
  const isBTCAddress = await validate(formData?.address);

  if (isEvmAddress) {
    console.log("56");
    if (!isEVMNetworkCondition) {
      newErrors.network = "Invalid EVM network";
    }
  }

  if (isEVMNetworkCondition) {
    console.log("78");
    if (!isEvmAddress) {
      newErrors.address = "EVM address not exist";
    }
  }

  if (isTronAddress) {
    if (!isTronNetworkCondition) {
      console.log("12");
      newErrors.network = "Invalid Tron network";
    }
  }

  if (isTronNetworkCondition) {
    if (!isTronAddress) {
      console.log("123");
      newErrors.address = "Tron address not exist";
    }
  }

  if (isBTCAddress) {
    if (!isBTCNetworkCondition) {
      console.log("12");
      newErrors.network = "Invalid BTC network";
    }
  }

  if (isBTCNetworkCondition) {
    if (!isBTCAddress) {
      console.log("123");
      newErrors.address = "BTC address not exist";
    }
  }

  if (!formData?.network?.trim()) {
    newErrors.network = "Network is required";
  }

  if (!formData?.symbol?.trim()) {
    newErrors.symbol = "Symbol is required";
  }

  if (!formData?.code?.trim()) {
    newErrors.code = "Code is required";
  }

  if (formData?.decimal <= 0) {
    newErrors.decimal = "Decimal is required and must be greater than zero";
  }

  if (formData?.minDeposit <= 0) {
    newErrors.minDeposit =
      "Minimum Deposit is required and must be greater than zero";
  }

  if (formData?.minWithdraw <= 0) {
    newErrors.minWithdraw =
      "Minimum Withdraw is required and must be greater than zero";
  }

  console.log("length: ", Object.keys(newErrors));
  return {
    isErrors: Object.keys(newErrors).length === 0,
    errorsCount: newErrors,
  };
};
