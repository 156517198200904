import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { validateForm, ValidateInputs } from "../../helper/helper";
import { ChainIdEnum, NetworkEnum } from "../../helper/helper";

const AddToken = () => {
  const [formData, setFormData] = useState({
    address: "",
    chainId: "",
    network: "",
    symbol: "",
    code: "",
    minWithdraw: 0,
    decimal: 0,
    minDeposit: 0,
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedFormData = { ...formData, [name]: value };

    // Set chainId based on network selection
    if (name === "network") {
      const chainIdMap = {
        [NetworkEnum.POLYGON]: ChainIdEnum.POLYGON,
        [NetworkEnum.BINANCE_SMART_CHAIN]: ChainIdEnum.BINANCE_SMART_CHAIN,
        [NetworkEnum.ETHEREUM]: ChainIdEnum.ETHEREUM,
        [NetworkEnum.TRON]: ChainIdEnum.TRON,
        [NetworkEnum.BITCOIN]: ChainIdEnum.BITCOIN,
      };
      updatedFormData.chainId = chainIdMap[value] || "";
    }

    setFormData(updatedFormData);
    const updatedInputs = ValidateInputs(name, value, errors);
    setErrors(updatedInputs);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const handleValidation = await validateForm(formData);
    const errCounts = handleValidation.errorsCount;
    const isErrors = handleValidation.isErrors;
    setErrors(errCounts);
    console.log("handleValidation", handleValidation);
    if (!isErrors) {
      console.log("gf");
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/token/add`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      toast.success("Token added successfully!");
      navigate("/withdrawal-limit");
    } catch (error) {
      console.error("Error adding token:", error);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Add Token</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <form id="quickForm" onSubmit={handleSubmit}>
                    <div className="card-body">
                      <>
                        <div className="form-group">
                          <label htmlFor="address">Address</label>
                          <input
                            type="text"
                            name="address"
                            className={`form-control ${
                              errors?.address ? "is-invalid" : ""
                            }`}
                            value={formData?.address}
                            onChange={handleChange}
                            placeholder="Enter address"
                            maxLength={100}
                          />
                          {errors?.address && (
                            <div className="invalid-feedback">
                              {errors?.address}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="network">Network</label>
                          <select
                            name="network"
                            className={`form-control ${
                              errors?.network ? "is-invalid" : ""
                            }`}
                            value={formData?.network}
                            onChange={handleChange}
                          >
                            <option value="">Select Network</option>
                            {Object.entries(NetworkEnum).map(([key, value]) => (
                              <option key={key} value={value}>
                                {value}
                              </option>
                            ))}
                          </select>
                          {errors?.network && (
                            <div className="invalid-feedback">
                              {errors?.network}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="symbol">Symbol</label>
                          <input
                            type="text"
                            name="symbol"
                            className={`form-control ${
                              errors?.symbol ? "is-invalid" : ""
                            }`}
                            value={formData?.symbol}
                            onChange={handleChange}
                            placeholder="Enter symbol"
                            maxLength={50}
                          />
                          {errors?.symbol && (
                            <div className="invalid-feedback">
                              {errors?.symbol}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="code">Code</label>
                          <input
                            type="text"
                            name="code"
                            className={`form-control ${
                              errors?.code ? "is-invalid" : ""
                            }`}
                            value={formData?.code}
                            onChange={handleChange}
                            placeholder="Enter code"
                            maxLength={50}
                          />
                          {errors?.code && (
                            <div className="invalid-feedback">
                              {errors?.code}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="decimal">Decimal</label>
                          <input
                            type="number"
                            name="decimal"
                            className={`form-control ${
                              errors?.decimal ? "is-invalid" : ""
                            }`}
                            value={formData?.decimal}
                            onChange={handleChange}
                            placeholder="Enter decimal"
                            maxLength={50}
                          />
                          {errors?.decimal && (
                            <div className="invalid-feedback">
                              {errors?.decimal}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="minWithdraw">Min Withdrawal</label>
                          <input
                            type="number"
                            name="minWithdraw"
                            className={`form-control ${
                              errors?.minWithdraw ? "is-invalid" : ""
                            }`}
                            value={formData?.minWithdraw}
                            onChange={handleChange}
                            placeholder="Enter minimum withdraw amount"
                            maxLength={50}
                          />
                          {errors?.minWithdraw && (
                            <div className="invalid-feedback">
                              {errors?.minWithdraw}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="minDeposit">Min Deposit</label>
                          <input
                            type="number"
                            name="minDeposit"
                            className={`form-control ${
                              errors?.minDeposit ? "is-invalid" : ""
                            }`}
                            value={formData?.minDeposit}
                            onChange={handleChange}
                            placeholder="Enter minimum deposit amount"
                            maxLength={50}
                          />
                          {errors?.minDeposit && (
                            <div className="invalid-feedback">
                              {errors?.minDeposit}
                            </div>
                          )}
                        </div>
                      </>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Add
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddToken;
