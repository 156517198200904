import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const Login = ({}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();

  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(""); // Clear email error when user starts typing
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError(""); // Clear password error when user starts typing
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (!email) {
  //     setEmailError("Please enter email.");
  //     return;
  //   }
  //   setError("");

  //   if (!password) {
  //     setError("Please enter password.");
  //     return;
  //   }
  //   setError("");
  //   // Validate email format
  //   if (!validateEmail(email)) {
  //     setError("Please enter a valid email address.");
  //     return;
  //   }
  //   setError("");
  //   try {
  //     await axios
  //       .post(`${process.env.REACT_APP_API_URL}/auth/login`, {
  //         email,
  //         password,
  //       })
  //       .then(async (response) => {
  //         console.log("responseeeeEData", response);
  //         console.log("responseeee", response?.data?.access_token);
  //         if (response?.data?.access_token) {
  //           // setIsAuthenticated(response.data.access_token)
  //           localStorage.setItem("token", response?.data?.access_token);
  //           toast.success("Login successfully!");
  //           navigate("/dashboard");
  //         } else {
  //           setError("Invalid email or password");
  //         }
  //       })
  //       .catch((error) => {
  //         const errorMessage =
  //           error?.response?.data?.response?.error ||
  //           "Invalid email or password.";
  //         toast.error(errorMessage);
  //       });
  //   } catch (error) {
  //     console.log("catch error", error);
  //     setError("Invalid email or password.");
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isValid = true;

    if (!email) {
      setEmailError("Please enter email.");
      isValid = false;
    } else if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Please enter password.");
      isValid = false;
    } else {
      setPasswordError("");
    }

    if (!isValid) return;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        { email, password }
      );

      if (response?.data?.access_token) {
        localStorage.setItem("token", response?.data?.access_token);
        toast.success("Login successfully!");
        navigate("/dashboard");
      } else {
        toast.error("Invalid email or password.");
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.response?.error || "Invalid email or password.";
      toast.error(errorMessage);
    }
  };

  //show password
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <div className="login-box">
        <div className="login-logo">
          <b style={{ fontSize: "Larger", color: "white" }}>Login</b>
        </div>
        {/* /.login-logo */}
        <div className="card login-card">
          <div className="card-body register-card-body">
            {/* <p className="login-box-msg">Login</p> */}

            <form onSubmit={handleSubmit}>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  value={email}
                  onChange={handleEmailChange}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
              </div>
              {emailError && <p className="text-danger">{emailError}</p>}
              <div className="input-group mb-3">
                <input
                  type={passwordVisible ? "text" : "password"}
                  className="form-control"
                  placeholder="Password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    {/* <span className="fas fa-eye" /> */}
                    <span onClick={togglePasswordVisibility}>
                      <i
                        className={
                          passwordVisible ? "fas fa-eye-slash" : "fas fa-eye"
                        }
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
              {passwordError && <p className="text-danger">{passwordError}</p>}
              <div className="row">
                <div className="col-12">
                  <button type="submit" className="btn btn-primary btn-block">
                    Login
                  </button>
                </div>
              </div>
            </form>
            <div className="social-auth-links text-center">
              <p>- OR -</p>
              <Link to="/forget-password" className="btn btn-block btn-danger">
                Forget Password
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
