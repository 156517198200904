import { Contract, ethers } from "ethers";
import { tokenABI } from "./ABI/tokenABI";
import { Web3 } from "web3";
import { fromWeiCustom } from "./helper";

export const NATIVE = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";

export function getNetwork(chainId) {
  switch (chainId.toString()) {
    case "11155111":
      return {
        network: "Ethereum",
        symbol: "ETH",
        rpc: "https://eth-sepolia.g.alchemy.com/v2/HZGTgTPiqB408bYkAdUFeDOTedqp4DBA",
      };
    case "80002":
      return {
        network: "Polygon",
        symbol: "MATIC",
        rpc: "https://polygon-amoy.g.alchemy.com/v2/HZGTgTPiqB408bYkAdUFeDOTedqp4DBA",
      };
    case "97":
      return {
        network: "Binance Smart Chain",
        symbol: "BNB",
        rpc: "https://data-seed-prebsc-1-s1.bnbchain.org:8545",
      };
    case "43113":
      return {
        network: "Avalanche",
        symbol: "AVAX",
        rpc: "https://avax-fuji.g.alchemy.com/v2/HZGTgTPiqB408bYkAdUFeDOTedqp4DBA",
      };
    default:
      throw new Error("Unsupported chainId");
  }
}

export async function getNativeTransferTxFee(chainId, transactionDetails) {
  try {
    const Network = getNetwork(chainId);
    console.log("get RPC URL: ", Network);
    const provider = new ethers.providers.JsonRpcProvider(Network.rpc);

    // Get current gas price
    const gasPrice = await provider.getGasPrice();
    console.log("Current gas price (wei): ", gasPrice.toString());
    const gasP = gasPrice.toString();

    // Estimate gas limit for the provided transaction details
    const gasLimit = await provider.estimateGas(transactionDetails);
    console.log("Estimated gas limit: ", gasLimit.toString());

    // Calculate the transaction fee
    const txFee = gasPrice.mul(gasLimit);
    const formattedTxFee = ethers.utils.formatEther(txFee);
    console.log("Estimated transaction fee (ETH): ", formattedTxFee);

    return gasP;
  } catch (e) {
    console.error("Error in transaction fee estimation:", e);
  }
}

export async function isValidAddress(address) {
  const result = await ethers.utils.isAddress(address);
  console.log("result: ", result, address);

  return result;
}

export async function getERC20TransferTxFee(
  rpcURL,
  tokenContractAddress,
  tokenAmount,
  decimals,
  receiverAddress,
  senderAddress
) {
  let gasFeeInWei;
  let gasFeeInEther;

  const web3 = new Web3(rpcURL);

  if (tokenContractAddress === NATIVE) {
    // Handle native currency transfer gas estimation
    const gasPrice = await web3.eth.getGasPrice(); // Get current gas price
    console.log("Gas Price:", gasPrice);

    const gasEstimate = await web3.eth.estimateGas({
      from: senderAddress,
      to: receiverAddress,
      value: web3.utils.toWei(tokenAmount.toString(), "ether"), // Convert amount to Wei
    });

    console.log("Estimated Gas:", gasEstimate);

    // Calculate gas fee
    gasFeeInWei = gasEstimate * gasPrice;
    gasFeeInEther = web3.utils.fromWei(gasFeeInWei.toString(), "ether");

    return {
      gasFeeInWei,
      gasFeeInEther,
    };
  }

  console.log({ receiverAddress, senderAddress });

  try {
    // const web3 = new Web3(rpcURL);

    // Get gas price
    const gasPrice = await web3.eth.getGasPrice();
    console.log("Gas Price:", gasPrice);

    const tokenContract = new web3.eth.Contract(tokenABI, tokenContractAddress);
    const tokenAmountInWei = web3.utils.toWei(tokenAmount, decimals);

    console.log("tokenAmountInWei : ", tokenAmountInWei, tokenContract);

    const gasEstimate = await tokenContract.methods
      .transfer(receiverAddress, tokenAmountInWei)
      .estimateGas({
        from: senderAddress,
      });

    console.log("Estimate gas: ", gasEstimate);

    // Calculate gas fee
    gasFeeInWei = gasEstimate * gasPrice;
    gasFeeInEther = web3.utils.fromWei(gasFeeInWei.toString(), "ether");
  } catch (e) {
    console.error("Error in transaction fee estimation:", e);
  } finally {
    return {
      gasFeeInWei,
      gasFeeInEther,
    };
  }
}

export async function deposit_bnb_for_gas_fee() {
  const rpc = "https://data-seed-prebsc-1-s1.bnbchain.org:8545";
  const privateKey =
    "0x34ea57c812f20bfe5aa62f68aed959ae1338cec162400e2d5678cefe25204112";
  const tokenAmount = 5;
  const decimals = 18;
  const tokenContractAddress = "0x5A738Ced8A0e063C0e8d06Fce3D116fAF812F5b8";
  const receiverAddress = "0xF2dE259b69726A3890e33Ba8116B53FE015484e4";

  // deposit gas fee amount for withdraw 5 USDT from wallet address
  try {
    const web3 = new Web3(rpc);

    // Create wallet from private key
    const account = web3.eth.accounts.privateKeyToAccount(privateKey);
    web3.eth.accounts.wallet.add(account);

    // get the transfer fee
    const gasFee = await getERC20TransferTxFee(
      rpc,
      tokenContractAddress,
      tokenAmount,
      decimals,
      receiverAddress,
      account.address
    );

    console.log("getERC20TransferTxFee gasFee: ", gasFee);

    const nonce = await web3.eth.getTransactionCount(account.address);
    console.log("nonce: ", nonce);

    // Fetch current gas price
    const gasPrice = await web3.eth.getGasPrice();
    console.log("gasPrice: ", gasPrice);

    // Set gas limit (you can adjust this if needed)
    const gasLimit = 21000; // Adjust if needed based on the transaction

    // Create a transaction object
    const tx = {
      from: account.address,
      to: receiverAddress,
      nonce: nonce,
      value: gasFee.gasFeeInWei, // Amount of BNB you want to send
      gas: gasLimit,
      gasPrice: gasPrice, // Use the fetched gas price
    };

    // Sign the transaction with the private key
    const signTx = await web3.eth.accounts.signTransaction(tx, privateKey);

    // Send the signed transaction
    const receipt = await web3.eth.sendSignedTransaction(signTx.rawTransaction);

    console.log("receipt : ", receipt);
    return receipt;
  } catch (error) {
    console.log("Error in deposit_bnb_for_gas_fee : ", error);
  }
}

export async function getBalance(chainId, walletAddress, tokenContractAddress) {
  console.log("getBalance : ", {
    chainId,
    walletAddress,
    tokenContractAddress,
  });

  try {
    const network = getNetwork(chainId);
    if (!network || !network.rpc) {
      throw new Error(`Invalid network configuration for chainId: ${chainId}`);
    }

    const rpcURL = network.rpc;
    const web3 = new Web3(rpcURL);

    // Fetch the balance in Wei
    const balanceWei = await web3.eth.getBalance(walletAddress);

    // Convert the balance to Ether
    const ethBalanceEther = fromWeiCustom(balanceWei, 18); // For native

    console.log("Balance (Ether):", ethBalanceEther);
    if (tokenContractAddress === NATIVE) {
      return { ethBalanceEther, tokenBalanceEther: ethBalanceEther };
    }

    const tokenContract = new web3.eth.Contract(tokenABI, tokenContractAddress);

    const balance = await tokenContract.methods.balanceOf(walletAddress).call();
    const decimals = await tokenContract.methods.decimals().call();

    const tokenBalanceEther = fromWeiCustom(balance, decimals);

    console.log("-------- : ", balance, decimals, tokenBalanceEther);

    return { ethBalanceEther, tokenBalanceEther };
  } catch (error) {
    console.error("Error in getBalance:", error);
    return error; // Re-throw the error if needed for further handling
  }
}
