import React, { useEffect, useState } from "react";
import axios from "axios";
import CustomPagination from "../../components/Pagination";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import { exportToCSV, exportToExcel, exportToPDF } from "../../helper/helper";

import Loader from "../../components/Loader";

const Merchant = () => {
  const [merchants, setMerchants] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const fetchMerchants = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/merchants/list`,
        {
          params: {
            pageNo: currentPage,
            limitVal: 10,
            search: searchQuery,
          },
        }
      );

      setMerchants(response?.data?.data);
      setTotalPages(response?.data?.totalPages);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMerchants();
  }, [currentPage, searchQuery]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  // const toggleUserStatus = async (userId, isActive) => {
  //   try {
  //     await axios.post(
  //       `${process.env.REACT_APP_API_URL}/merchants/change-status`,
  //       {
  //         id: userId,
  //         isActive: !isActive,
  //       }
  //     );

  //     fetchMerchants();
  //     toast.success("Status updated succesfully!");
  //   } catch (error) {
  //     console.error("Error toggling user status:", error);
  //   }
  // };

  const handleExportToCSV = () => {
    try {
      const headers = [
        { label: "S.N.", key: "sn" },
        { label: "Name", key: "name" },
        { label: "Email", key: "email" },
        { label: "Platform Name", key: "platformName" },
        { label: "Platform Category", key: "platformCategory" },
        { label: "Contact Number", key: "contactNumber" },
        { label: "Description", key: "description" },
      ];

      const formattedData = merchants.map((user, index) => ({
        sn: index + 1,
        name: user?.name || "",
        email: user?.email || "",
        platformName: user?.platformName || "",
        platformCategory: user?.platformCategory || "",
        contactNumber: user?.contactNumber || "",
        description: user?.description || "",
      }));
      exportToCSV(formattedData, headers, "merchants.csv");
    } catch (error) {
      console.log("Failed to export to CSV : ", error.message);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  console.log("merchants-rahul : ", merchants);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2 d-flex justify-content-between">
              <div className="col-sm-6">
                <h1 className="m-0">Merchant Management</h1>
              </div>
              <div className="dropdown">
                <button
                  className="btn btn-primary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  Download Report
                  <span className="caret"></span>
                </button>
                <ul className="dropdown-menu">
                  <li
                    style={{ cursor: "pointer", backgroundColor: "#f8f9fa" }}
                    onClick={() => exportToPDF(merchants)}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = "#D3D3D3")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = "#f8f9fa")
                    }
                  >
                    Download PDF
                  </li>
                  <li
                    style={{ cursor: "pointer", backgroundColor: "#f8f9fa" }}
                    onClick={() => exportToExcel(merchants)}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = "#D3D3D3")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = "#f8f9fa")
                    }
                  >
                    Download Excel
                  </li>

                  <li
                    style={{ cursor: "pointer", backgroundColor: "#f8f9fa" }}
                    onClick={handleExportToCSV}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = "#D3D3D3")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = "#f8f9fa")
                    }
                  >
                    Download CSV
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <input
                      type="text"
                      placeholder="Search merchants..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                    <table className="table">
                      <thead>
                        <tr>
                          <th>S.N.</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Contact Number</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {merchants?.length ? (
                          merchants.map((user, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{user?.name}</td>
                              <td>{user?.email}</td>
                              <td>
                                {user?.countryCode + " " + user?.contactNumber}
                              </td>
                              <td>
                                <Link
                                  to={`/merchants-view/${user?._id}`}
                                  className="btn btn-link merch-view"
                                >
                                  <i className="fas fa-eye"></i>
                                </Link>
                                <Link
                                  to={`/merchants-edit/${user?._id}`}
                                  className="btn btn-link merch-edit"
                                >
                                  <i className="fas fa-edit"></i>
                                </Link>
                                {/* <button
                                  className={`btn btn-link ${
                                    user.isActive ? "text-success" : "text-danger"
                                  }`}
                                  onClick={() =>
                                    toggleUserStatus(user._id, user.isActive)
                                  }
                                >
                                  {user.isActive ? (
                                    <i className="fas fa-toggle-on"></i>
                                  ) : (
                                    <i className="fas fa-toggle-off"></i>
                                  )}
                                </button> */}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                  marginTop: "20px",
                                }}
                              >
                                <p>Data not found</p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <CustomPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Merchant;
